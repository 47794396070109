import React, { useRef } from "react";
import { Button } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import theme from "../JeanStyles/theme";
import DEMobile from "../Images/DE/DEMobile.png";
import DEDesktop from "../Images/DE/DEDesktop.png";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";
import Grid from "@mui/material/Grid";
import BOM from "../Images/background/BOM.png";
import { ReactComponent as BomDesktopLogo } from "../Images/background/BOM-logo-Desktop.svg";
import { ReactComponent as BomMobileLogo } from "../Images/background/BOM-logo-Mobile.svg";
import Technologies from "../HomePage/TechUtilised";


export default function DigitalEngineeringPage() {
  const dispatch = useDispatch();
  let DEVRef = useRef();
  let OPSRef = useRef();
  let BOMRef = useRef();

  const goToDEVRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: DEVRef.current.offsetTop - 100,
    });
  };
  const goToOPSRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: OPSRef.current.offsetTop - 100,
    });
  };
  const goToBOMRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: BOMRef.current.offsetTop - 100,
    });
  };

  return (
    <div>
      <TopDescription
        goToDEVRef={goToDEVRef}
        goToOPSRef={goToOPSRef}
        goToBOMRef={goToBOMRef}
      />

      <Development setRef={DEVRef} />

      <Operations setRef={OPSRef} />

      <BusiOpsMan setRef={BOMRef} />

      <CoreTechnologies />
    </div>
  );
}

function TopDescription({ goToDEVRef, goToOPSRef, goToBOMRef }) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      sx={{ mt: 2, mb: 4, width: "100%" }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <div
        style={{
          background: `url(${isMobile ? DEMobile : DEDesktop}) `,
          ...(isMobile
            ? theme.deTopMobile.background.image
            : theme.deTopBrowser.background.image),
        }}
      >
        <Grid
          item
          align="left"
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.deTopMobile.headings.main
              : theme.deTopBrowser.headings.main
          }
        >
          DE
        </Grid>

        <Grid
          item
          align="left"
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.deTopMobile.headings.sub
              : theme.deTopBrowser.headings.sub
          }
        >
          Digital Engineering
        </Grid>

        <Grid
          sx={{ mt: 2, mb: 4 }}
          item
          align="left"
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.deTopMobile.paragraphs.main
              : theme.deTopBrowser.paragraphs.main
          }
        >
          Develop and operate digital systems that automate operational tasks to
          improve your time and resource allocation. Our Digital Engineering
          services strategically target streamlining business and project
          processes to save you valuable time and ensure repeatable results.
        </Grid>
        <Grid
          item
          align="left"
          xs={12}
          md={12}
          lg={12}
          sx={{ ml: { lg: "20px" } }}
          style={
            isMobile
              ? theme.deTopMobile.bullets.main
              : theme.deTopBrowser.bullets.headers
          }
        >
          <div
            class={isMobile ? "vl-de-mobile-services" : "vl-top-de-left"}
            onClick={goToDEVRef}
          >
            {" "}
            Software Design <br></br> and Development
          </div>
          <div
            class={isMobile ? "vl-de-mobile-services-long" : "vl-top-de-center"}
            onClick={goToOPSRef}
          >
            {" "}
            Systems Operations <br></br> and Maintenance
          </div>
          <div
            class={isMobile ? "vl-de-mobile-services" : "vl-top-de-right"}
            onClick={goToBOMRef}
          >
            BusiOpsMan DE <br></br> Platform
          </div>
          <br />
        </Grid>
        <Grid
          item
          align={isMobile ? "center" : "left"}
          xs={12}
          md={12}
          lg={4.5}
          sx={isMobile ? { mt: 45 } : { paddingLeft: { lg: "200px" } }}
        >
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={() => dispatch(setContactUsModalOpen(true))}
          >
            <MessageIcon
              style={{ paddingRight: "5px", paddingTop: "5px" }}
            ></MessageIcon>
            LET'S TALK ABOUT YOUR PROJECT
          </Button>
        </Grid>
      </div>
    </Grid>
  );
}

function Development({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          sx={{ mb: { lg: -20 }, ml: { lg: 5 } }}
          style={
            isMobile
              ? theme.deTopMobile.bullets.main
              : theme.deTopBrowser.bullets.main
          }
        >
          <div
            class={
              isMobile ? "vl-de-mobile-services" : "vl-de-desktop-services"
            }
          >
            {" "}
            Software Design and Development
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ ml: { lg: 5 } }}
          style={
            isMobile
              ? theme.deMobile.paragraphs.main
              : theme.deBrowser.paragraphs.main
          }
        >
          Accelerate your digital evolution with Cauchy's Digital Engineering
          expertise. Lead the transition from concept to product using our
          full-stack development capabilities. Tailor requirements to your
          precise budget and timelines while maintaining full code ownership for
          uninterrupted integration. Welcome to a technically advanced era of
          innovation.
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4.5}
          sx={{ ml: { lg: 5 }, mb: { xs: -9.5 } }}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>UI/UX Design</li>
            <li>Front-end & Back-end development</li>

            <li>Database Management Systems (DBMS)</li>
            <li>Application Programming Interfaces (APIs)</li>
            <li>3rd Party Integration </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Hardware design and development</li>

            <li>On-premises or remote consultation for system requirements</li>
            <li>Project documentation and management</li>
            <li>Systems architecture design</li>

            <li>Hand-over with comprehensive implementation/training</li>
          </ul>
        </Grid>
        <Grid item lg={1.5} />
      </Grid>
    </div>
  );
}

function Operations({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2} sx={isMobile ? {} : { mt: 10 }}>
        <Grid item lg={2.5} />
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          style={
            isMobile
              ? theme.deTopMobile.bullets.main
              : theme.deTopBrowser.bullets.right
          }
        >
          <div
            class={
              isMobile
                ? "vl-de-mobile-services-long"
                : "vl-de-desktop-services-right"
            }
          >
            {" "}
            Systems Operations and Maintenance
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={11.5}
          sx={{ ml: { lg: 5 } }}
          style={
            isMobile
              ? theme.deMobile.paragraphs.main
              : theme.deBrowser.paragraphs.main
          }
        >
          <p>
            Dedicated digital systems operations management and DevOps
            engineering for system deployment and continuous delivery. Ensure
            the reliability, maintainability, and security of your applications.
            Maximize application uptime and enhance system performance.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4.5}
          sx={{ ml: { lg: 5 }, mb: { xs: -9.5 } }}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Deployment control & automation</li>
            <li>CI/CD pipeline</li>
            <li>Cyber Security</li>
            <li>Technical support</li>
          </ul>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Cloud infrastructure management</li>
            <li>Infrastructure Monitoring</li>
            <li>Database backup management</li>
            <li>Disaster Recovery</li>
          </ul>
        </Grid>

        <Grid item lg={1.5} />
      </Grid>
    </div>
  );
}

function BusiOpsMan({ setRef }) {
  const dispatch = useDispatch();
  return (
    <div ref={setRef}>
      <Grid container spacing={2} sx={isMobile ? {} : { mt: 10 }}>
        {isMobile ? (
          <>
            <Grid item xs={12} md={12}>
              <BomMobileLogo />
            </Grid>
            <Grid item xs={12} md={12}>
              <img src={BOM} width="300" />
            </Grid>
            <Grid item xs={12} md={12}>
            <Button variant="contained"  onClick={() => dispatch(setContactUsModalOpen(true))}>Learn more</Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item lg={1} />
            <Grid item lg={5}>
              <img src={BOM} width="500" />
            </Grid>
            <Grid item lg={5}>
              <BomDesktopLogo />

              <br />
              <br />
              <Button variant="contained"  onClick={() => dispatch(setContactUsModalOpen(true))}>Learn more</Button>
            </Grid>
            <Grid item lg={1} />
          </>
        )}
        <Grid item xs={12} />
        <Grid
          item
          xs={12}
          md={12}
          lg={11}
          sx={{ ml: { lg: 5 } }}
          style={
            isMobile
              ? theme.deMobile.paragraphs.main
              : theme.deBrowser.paragraphs.main
          }
        >
          <p>
            A digital engineering platform that empowers clients to create
            custom software, actively shaping and managing their business.
            Define your requirements, own your source code, and customize
            workflows, putting you in control of the software that drives your
            success. It's more than a platform; it's your tool for hands-on
            business management and software creation. Welcome to the forefront
            of innovation and client-driven digital excellence.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4.5}
          sx={{ ml: { lg: 5 }, mb: { xs: -9.5 } }}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Personalized Digital Engineering Manager</li>
            <li>Intuitive end-user feedback system</li>

            <li>User-drive development</li>
            <li>Tailored digital ecosystems</li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Data-driven decision making and management</li>
            <li>Process workflow optimization</li>
            <li>Internal and external collaboration</li>
            <li>Managed development and operations services</li>
          </ul>
        </Grid>
        <Grid item lg={1.5} sx={{ mb: { lg: 50 } }} />
      </Grid>
    </div>
  );
}

function CoreTechnologies() {
  return (
    <div
      style={{ marginBottom: "120px", height: "100%", backgroundColor: "#fff" }}
    >
      <Technologies />
    </div>
  );
}
