import React from "react";
import { ReactComponent as DesktopIcon } from "../Images/background/footer_logo.svg";
import { ReactComponent as MobileIcon } from "../Images/navLogo.svg";

import {
  isBrowser,
} from "react-device-detect";

export default function FooterLogo() {
  return (
        
          isBrowser? <DesktopIcon /> : <MobileIcon width="70%" height="80%"/>
          
  );
}
