import { Routes, Route } from "react-router-dom";
import React from "react";
import DEPage from "./DEPage/DEPage";
import ECPage from "./ECPage/ECPage";
import CAEPage from "./CAEPage/CAEPage";
import ContactForm from "./ContactForm/ContactModal";
import Home from "./HomePage/HomeNew";
import About from "./AboutPage/About";
import PrivacyPolicy from "./PrivacyPolicyPage";
import SendWhatsapp from "./SendWhatsapp";
import Navbar from "./Navbar";
import SpeedDialTooltipOpen from "./SpeedDial";
import Footer from "./Footer/Footer.js";
import Notifications from "./Notifications";
import WatchVideo from "./Video";


const MainRouter = () => (
  <>
    <Navbar position="fixed" />
    <SendWhatsapp />
    <WatchVideo />
    {/* <SpeedDialTooltipOpen /> */}
    <ContactForm />
    <Notifications />

    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        exact
        path="/our-services/engineering-consulting"
        element={<ECPage />}
      />
      <Route
        exact
        path="/our-services/digital-engineering"
        element={<DEPage />}
      />
      <Route
        exact
        path="/our-services/computer-aided-engineering"
        element={<CAEPage />}
      />
      <Route exact path="/our-purpose" element={<About />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>

    <Footer />
  </>
);

export default MainRouter;
