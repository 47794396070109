function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function getToken() {
  const token = `${getCookie("access")}`;
  return token;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function setCookie(token) {
  let manager = parseJwt(token.access).Manager;
  document.cookie = `refresh=${token.refresh};max-age=86400;domain=${process.env.REACT_APP_SECRET_DOMAIN}`;
  document.cookie = `access=${token.access};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN}`;
  if (manager) {
    document.cookie = `manager=${manager};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN}`;
  }
}

function concatPathWithParams(path, queryParams) {
  let pathWithParams = path;

  if (queryParams && Object.keys(queryParams).length) {
    pathWithParams += `?${_queryParamsToQueryString(queryParams)}`;
  }

  return pathWithParams;
}

function _queryParamsToQueryString(queryParams) {
  return Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");
}

module.exports = {
  concatPathWithParams,
  getCookie,
  getToken,
  setCookie,
};
