import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import homeMobile from "./Images/background/BG-TopNav-Mobile.png";
import homeDesktop from "./Images/background/BG-TopNav-Desktop.png";

export const Nav = styled.nav`
  background-image: url(${homeDesktop});
 
  height: 80px;
 
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;

  flexDirection: column;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 600px) {
    background-image: url(${homeMobile});
    height: 60px;
    justify-content: left;
  
   

`;

export const NavExpanded = styled.nav`
  background-image: url(${homeDesktop});
  height: 100px;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: top;
  flexDirection: column;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 600px) {
    background-image: url(${homeMobile});
    justify-content: left;

`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  
  cursor: pointer;
  &.active {
    color: #fff;
  }
  @media screen and (max-width: 600px) {

`;

export const ImgLink = styled(Link)`
  color: #fff;

  align-items: center;

  text-decoration: none;
  padding: 0 1rem;

  top: 20px;
  left: 50px;
  position: fixed;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }

  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    position: absolute;
    left: 650px;
    top: 0;
    right: 1%;
  }

  @media screen and (max-width: 800px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }

  @media screen and (max-width: 600px) {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    left: 300px;

    cursor: pointer;
    &.active {
      color: #15cdfc;
    }
  }

  @media screen and (max-width: 370px) {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    left: 250px;

    cursor: pointer;
    &.active {
      color: #15cdfc;
    }
  }
`;

export const Bars = styled(MenuIcon)`
  display: none;
  color: #fff;
  @media screen and (max-width: 601px) {
    display: block;
    position: absolute;
    justify-content: center;
    left: 0;
    bottom: 50%;
    transform: translate(100%, 55%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 220px;
  margin-right: 220px;

  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 600px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
export const NavMenuFlex = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;

  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 600px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const NavBtn = styled.nav`
  align-items: center;

  top: 20px;
  right:50px;
  position: fixed;
  margin-left: 15px;
  
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const NavBtn2 = styled.nav`
  align-items: center;

  top: 20px;
  right:100px;
  position: fixed;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const NavBtn3 = styled.nav`
  align-items: center;

  top: 20px;
  right:150px;
  position: fixed;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;