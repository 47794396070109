import * as React from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputAdornment, Box, Link, Grid } from "@mui/material";
import { ReactComponent as SvgFile } from "../Images/ModalImages/ContactLogo.svg";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import theme from "../JeanStyles/theme";
import theme2 from "../Styles/theme";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { getContactUsModalOpen, setContactUsModalOpen } from "../appSlice";
import { useSendEmailMutation } from "../../Features/api/apiSlice";
import { showSuccess, showError } from "../notificationsSlice";

export default function ContactForm() {
  const [state, setState] = React.useState({
    privpol: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { privpol } = state;
  const error = [privpol].filter((v) => v).length !== 1;
  const dispatch = useDispatch();
  const contact_us_modal_open = useSelector(getContactUsModalOpen);
  const [
    sendEmail,
    {
      data: email_data,
      isSuccess: isEmailSuccess,
      error: email_error,
      isError: isEmailError,
      isLoading, isEmailLoading
    },
  ] = useSendEmailMutation();

  const [message, setmessage] = React.useState("");
  const [name, setname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [error2, setError] = React.useState(true);
  const [disabledButton, setDisabledButton] = React.useState(false);
  const subject = "Website Enquiry";

  const handleChangeName = (event) => {
    setname(event.target.value);
  };
  const handleChangeWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setmessage(event.target.value);
  };

  const handleChangePhone = (event) => {
    setphone(event.target.value);
  };

  const handleUpdate = () => {
    const body = {
      user_name: name,
      email_address: email,
      message: message,
      subject: subject,
      phone: phone,
      website: website,
    };
    setDisabledButton(true);
    sendEmail({ body: body });
    setError(true);
  };

  const handleClose = () => {
    dispatch(setContactUsModalOpen(false));
  };

  useEffect(() => {
    if (isEmailLoading) {
   
      setDisabledButton(true);
    } 
    else if (isEmailSuccess) {
      dispatch(setContactUsModalOpen(false));
      dispatch(showSuccess(email_data.success_message));
      setDisabledButton(false);
      setmessage("");
      setname("");
      setemail("");
      setphone("");
    } else if (isEmailError) {
      dispatch(showError(email_error.data.error_message));
      setDisabledButton(false);
    }
  }, [isEmailSuccess, isEmailError]);

  return (
    <>
      <Dialog
        open={contact_us_modal_open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "720px!important",
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="start">
            <SvgFile
              style={{
                width: theme.svgIcon.width,
                height: theme.svgIcon.height,
                fill: theme.svgIcon.fill,
              }}
            />
          </Box>
          <Typography variant="body 3">
            <strong>Contact Us</strong>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "auto", maxHeight: "80vh" }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PersonOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField
              id="input-with-sx"
              fullWidth
              onChange={handleChangeName}
              value={name}
              label="Name"
              variant="standard"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <EmailOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField
              id="input-with-sx"
              fullWidth
              onChange={handleChangeEmail}
              value={email}
              label="Email"
              variant="standard"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LocalPhoneOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField
              id="input-with-sx"
              fullWidth
              onChange={handleChangePhone}
              value={phone}
              label="Phone Number"
              variant="standard"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LinkOutlinedIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              fullWidth
              label="Website"
              onChange={handleChangeWebsite}
              value={website}
              variant="standard"
            />
          </Box>
          <TextField
       
            id="filled-multiline-static"
            fullWidth
            label="Your Message"
            onChange={handleChangeMessage}
            value={message}
            multiline
            rows={4}
            variant="filled"
            sx={{ mt: 2, mb: 2 }}
            InputLabelProps={{ shrink: true }}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button color="primary" variant="contained" disabled={disabledButton} onClick={handleUpdate}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
