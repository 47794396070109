import React, { useRef } from "react";
import theme from "../../Components/Styles/theme";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import HomePageIcon from "./HomePageIcon";
import { ReactComponent as DEIcon } from "../Images/background/DE.svg";
import { ReactComponent as ECIcon } from "../Images/background/EC.svg";
import { ReactComponent as CAEIcon } from "../Images/background/CAE.svg";
import { ReactComponent as DEIconDesktop } from "../Images/background/DEdesktop.svg";
import { ReactComponent as ECIconDesktop } from "../Images/background/ECdesktop.svg";
import { ReactComponent as CAEIconDesktop } from "../Images/background/CAEdesktop.svg";
import { isBrowser, isMobile } from "react-device-detect";
import homeDesktop from "../Images/background/LANDING-BG-SCALED-Desktop.png";
import homeMobile from "../Images/background/LANDING-BG-SCALED-MOBILE-1.png";
import { ReactComponent as SvgFile } from "../Images/navLogo.svg";
import bomMobile from "../Images/background/BOM-BG_Mobile.png";
import bomDesktop from "../Images/background/BOM-BG_Mobile.png";
import bomMobileComputer from "../Images/background/BOM.png";

import { ReactComponent as BomDesktopLogo } from "../Images/background/BOM-logo-Desktop.svg";
import { ReactComponent as BomMobileLogo } from "../Images/background/BOM-logo-Mobile.svg";
import ClientRatings from "./ClientRatings";
import Technologies from "./TechUtilised";
import LinkIcon from "@mui/icons-material/Link";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";

function Home() {
  let CAERef = useRef();
  let DERef = useRef();
  let ECRef = useRef();

  const goToCAERef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: CAERef.current.offsetTop - 100,
    });
  };
  const goToDEREef = () => {
    window.scrollTo({ behavior: "smooth", top: DERef.current.offsetTop - 100 });
  };

  const goToECRef = () => {
    window.scrollTo({ behavior: "smooth", top: ECRef.current.offsetTop - 100 });
  };

  const goToAbout = () => {
    window.location.pathname = "/our-purpose";
  };

  return (
    <>
      <div
        style={
          isMobile
            ? { backgroundImage: `url(${homeMobile})`, backgroundSize: "cover" }
            : {
                backgroundImage: `url(${homeDesktop})`,
                backgroundSize: "cover",
              }
        }
      >
        <HomeLandingView
          goToAbout={goToAbout}
          goToCAERef={goToCAERef}
          goToDEREef={goToDEREef}
          goToECRef={goToECRef}
        />
        <Services ECRef={ECRef} DERef={DERef} CAERef={CAERef} />
        <ClientRatings />
        <BOM />

        <ContactGrids />
      </div>
    </>
  );
}
function ContactGrids() {
  return (
    <>
      <div
        style={{
          height: "110%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <Grid
          container
          sx={{ mt: 2, mb: 4, width: "100%" }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {isBrowser ? <ContactDesktop /> : <ContactMobile />}
        </Grid>
      </div>
    </>
  );
}
function ContactDesktop() {
  return (
    <Grid item align="center" lg={12}>
      <p style={theme.homeHeadingsDesktop.root}>Contact </p>

      <Card style={theme.contactCardStyleDesktop}>
        <CardContent>
          <Grid
            container
            sx={{ mt: 2, mb: 4, width: "100%" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item align="center" lg={12}>
              <SvgFile alignItems="right" width={"40%"} height={"30%"} />
            </Grid>
            <Grid item align="center" lg={12}>
              <p style={theme.contactMainHeading.root}>A Global Team</p>
            </Grid>

            <Grid item align="center" lg={12}>
              <ContactShared />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
function ContactMobile() {
  return (
    <Grid item align="center" xs={12}>
      <Card style={theme.contactCardStyle}>
        <CardContent>
          <SvgFile width={"30%"} height={"30%"} />

          <p style={theme.contactMainHeading.root}>A Global Team</p>
          <ContactShared />
        </CardContent>
      </Card>
    </Grid>
  );
}
function ContactShared() {
  const dispatch = useDispatch();

  return (
    <>
      <p style={theme.contactMainHeading.sub}>Find Your Nearest Consultant</p>
      <br />
      <Button
        startIcon={<EmailIcon />}
        onClick={() => dispatch(setContactUsModalOpen(true))}
        variant="contained"
      >
        Email Now
      </Button>
      <br />
    </>
  );
}

function BOMListDivs({ text, text2 }) {
  return (
    <>
      <div class={isMobile ? "bom-list-mobile" : "bom-list"}>
        <p
          style={
            isMobile
              ? theme.bomParagraphHomeMobile.root
              : theme.bomBulletsDesktop.root
          }
        >
          {text} <br /> {text2}
        </p>
      </div>
      <br />
    </>
  );
}
function BOM() {
  const dispatch = useDispatch();
  return (
    <div
      style={
        isMobile
          ? {
              backgroundImage: `url(${bomMobile})`,
              marginTop: "20px",
              height: "100%",
            }
          : {
              backgroundImage: `url(${bomDesktop})`,
              marginTop: "20px",
              height: "100%",
              backgroundSize: "cover",
            }
      }
    >
      <Grid
        container
        sx={
          isBrowser
            ? { mt: 2, mb: 4, width: "100%", p: 4 }
            : { mt: 2, mb: 4, width: "100%", p: 3 }
        }
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item align="center" lg={12}>
          {isMobile ? <BomMobileLogo /> : <BomDesktopLogo />}
        </Grid>
        <Grid item align="center" lg={12}>
          <img
            src={bomMobileComputer}
            width="300px"
            position="absolute"
            alt=""
          />
        </Grid>

        <Grid item align="center" lg={2} />
        <Grid item align="center" lg={8}>
          <p
            style={
              isMobile
                ? theme.bomHeaderHomeMobile.root
                : theme.bomSubHeaderHomeDesktop.root
            }
          >
            BusiOpsMan and your business.
          </p>
          <p
            style={
              isMobile
                ? theme.bomParagraphHomeMobile.sub
                : theme.bomParagraphHomeDesktop.root
            }
          >
            Tailor software solutions for your business operations management. Drive development, prioritise feedback, and empower users with our intuitive BusiOpsMan platform.
          </p>
          <Grid
            container
            sx={{ mt: 2, mb: 2, width: "100%" }}
            spacing={isBrowser ? 3 : {}}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Grid item align="center" xs={6}>
              <Button startIcon={<LinkIcon />} variant="contained">
                Learn More
              </Button>
            </Grid> */}
            <Grid item align="center" xs={12}>
              <Button variant="contained"  onClick={() => dispatch(setContactUsModalOpen(true))}>Book a demo</Button>
            </Grid>
            <Grid item align="center" lg={1.5} />
            <Grid item align="left" xs={6} lg={4.5}>
              <BOMListDivs
                text={"Data-driven"}
                text2={"decision making"}
              />
              <BOMListDivs
                text={"Managed development"}
                text2={"and operations"}
              />
              <BOMListDivs text={"Process workflow"} text2={"optimisation"} />
              {/* <BOMListDivs text={"Internal and"} text2={"external collaboration"} /> */}
            </Grid>

            <Grid item align="left" xs={6} lg={4.5}>
              <BOMListDivs
                text={"User Workflow"}
                text2={"customisation"}
              />
              <BOMListDivs
                text={"Hand-over,"}
                text2={"implementation, training"}
              />
              <BOMListDivs
                text={" Crafted user"}
                text2={"experiences and interfaces"}
              />
              {/* <BOMListDivs text={" Hand-over,"} text2={"implementation, training"} /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item align="center" lg={1.5} />
      </Grid>
    </div>
  );
}

function CardHeadings({
  heading,
  heading2,
  mobileIcon,
  abbrv,
  paragraph,
  button,
}) {
  return (
    <>
      <Grid
        container
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="justify"
        alignItems="center"
      >
        <Grid item align="left" xs={8} lg={6}>
          <p
            style={
              isMobile
                ? theme.mobileHomeServicesAbbrvHeading.root
                : theme.desktopHomeServicesAbbrvHeading.root
            }
          >
            {" "}
            {abbrv}
          </p>
          <p
            style={
              isMobile
                ? theme.mobileHomeServicesHeading.root
                : theme.desktopHomeServicesHeading.root
            }
          >
            {heading}
            <br />
            {heading2}
          </p>
        </Grid>
        {isMobile ? (
          <Grid item align="right" xs={2}>
            {mobileIcon}
          </Grid>
        ) : (
          ""
        )}

        {isBrowser ? (
          <>
            {" "}
            <Grid item align="left" lg={10} sx={{ mt: -2 }}>
              {" "}
              {paragraph} {button}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}

function CAEParagraph() {
  return (
    <p
      style={
        isMobile
          ? theme.paragraphServicesMobile
          : theme.paragraphServicesDesktop
      }
    >
      Transform complex challenges into precise solutions through cutting-edge
      simulations and analysis. Our expertise propels innovation, driving your
      projects and products to new heights of efficiency and excellence.
    </p>
  );
}

function DEParagraph() {
  return (
    <p
      style={
        isMobile
          ? theme.paragraphServicesMobile
          : theme.paragraphServicesDesktop
      }
    >
      Integrate technology and strategy to automate your processes and optimise
      your product’s lifecycle. Streamline processes and elevate product
      outcomes with our team.{" "}
    </p>
  );
}
function ECParagraph() {
  return (
    <p
      style={
        isMobile
          ? theme.paragraphServicesMobile
          : theme.paragraphServicesDesktop
      }
    >
      Elevate your products and services through engineering and technology
      management, collaborating with our highly skilled professionals to achieve
      the best outcome.{" "}
    </p>
  );
}
function CTA({ goToCAE, goToDEM, goToMEC, type }) {
  return (
    <Button
      onClick={type == "CAE" ? goToCAE : type == "DE" ? goToDEM : goToMEC}
      variant="contained"
    >
      Find out more
    </Button>
  );
}
function Services({ ECRef, DERef, CAERef }) {
  const goToDEM = () => {
    window.location.pathname = "/our-services/digital-engineering";
  };
  const goToCAE = () => {
    window.location.pathname = "/our-services/computer-aided-engineering";
  };
  const goToMEC = () => {
    window.location.pathname = "/our-services/engineering-consulting";
  };
  return (
    <>
      <p
        style={
          isMobile
            ? theme.homeHeadingsMobile.root
            : theme.aboutHeadingDesktop.services
        }
      >
        Services
      </p>
      <Grid
        container
        sx={{ mt: 2, mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={0.5} />

        <Grid item align="left" xs={11.5}>
          <Card
            ref={CAERef}
            id="CAERef"
            style={
              isMobile
                ? theme.mobileServicesCardStyle.cae
                : theme.desktopServicesCardStyle.cae
            }
          >
            <CardContent>
              <Grid
                container
                sx={{ mb: 4, width: "100%" }}
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                <Grid item align="left" lg={2} />
                <Grid item align="left" lg={7} sx={isBrowser ? { mt: 1 } : {}}>
                  <div
                    class={isMobile ? "vl-cae-mobile-cards" : "vl-cae-cards"}
                  >
                    <CardHeadings
                      heading={"Computer-Aided"}
                      heading2={"Engineering"}
                      abbrv={"CAE"}
                      mobileIcon={<CAEIcon align="right" />}
                      paragraph={<CAEParagraph />}
                      button={<CTA goToCAE={goToCAE} type="CAE" />}
                    />
                    {isMobile ? (
                      <>
                        <CAEParagraph />
                        <CTA goToCAE={goToCAE} type="CAE" />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
                {isBrowser ? (
                  <Grid
                    item
                    align="center"
                    lg={3}
                    sx={isBrowser ? { mt: 1 } : {}}
                  >
                    <CAEIconDesktop />
                  </Grid>
                ) : (
                  ""
                )}
                {/* <Grid item align="left" lg={2} /> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ mb: 4 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item align="left" xs={12}>
          <Card
            ref={DERef}
            id="DERef"
            style={
              isMobile
                ? theme.mobileServicesCardStyle.de
                : theme.desktopServicesCardStyle.de
            }
          >
            <CardContent>
              <Grid
                container
                sx={{ mb: 4, width: "100%" }}
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                <Grid item align="left" lg={2.4} />
                <Grid
                  item
                  align="left"
                  lg={5.6}
                  sx={isBrowser ? { mt: 5 } : {}}
                >
                  <div class={isMobile ? "vl-de-mobile-cards" : "vl-de-cards"}>
                    <CardHeadings
                      heading={"Digital"}
                      heading2={"Engineering"}
                      mobileIcon={<DEIcon align="right" />}
                      abbrv={"DE"}
                      paragraph={<DEParagraph />}
                      button={<CTA goToDEM={goToDEM} type="DE" />}
                    />
                    {isMobile ? (
                      <>
                        <DEParagraph />
                        <CTA goToDEM={goToDEM} type="DE" />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
                {isBrowser ? (
                  <Grid
                    item
                    align="left"
                    lg={4}
                    sx={isBrowser ? { mt: 1 } : {}}
                  >
                    <DEIconDesktop />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item align="left" xs={11.5}>
          <Card
            ref={ECRef}
            id="ECRef"
            style={
              isMobile
                ? theme.mobileServicesCardStyle.ec
                : theme.desktopServicesCardStyle.ec
            }
          >
            <CardContent>
              <Grid
                container
                sx={{ mb: 4, width: "100%" }}
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                <Grid item align="left" lg={2.5} />
                <Grid
                  item
                  align="left"
                  lg={5.5}
                  sx={isBrowser ? { mt: 5 } : {}}
                >
                  <div class={isMobile ? "vl-ec-mobile-cards" : "vl-ec-cards"}>
                    <CardHeadings
                      heading={"Engineering"}
                      heading2={"Consulting"}
                      abbrv={"EC"}
                      mobileIcon={<ECIcon align="right" />}
                      paragraph={<ECParagraph />}
                      button={<CTA goToMEC={goToMEC} type="EC" />}
                    />
                    {isMobile ? (
                      <>
                        <ECParagraph />
                        <CTA goToMEC={goToMEC} type="EC" />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
                {isBrowser ? (
                  <Grid item align="left" lg={4} sx={{ mt: 1 }}>
                    <ECIconDesktop />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={0.5} />
      </Grid>
    </>
  );
}
function HomeLandingView({ goToAbout, goToCAERef, goToDEREef, goToECRef }) {
  return (
    <Grid
      container
      gap={2}
      sx={isBrowser ? { mt: 6, mb: 4, p: 4 } : { mt: 6, mb: 4, p: 2 }}
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <div
        style={
          isMobile ? { height: "100%" } : { height: "100%", marginTop: "60px" }
        }
      >
        <HomePageIcon goToAbout={goToAbout} />
        <Grid
          item
          lg={12}
          align="center"
          sx={isBrowser ? { mt: 10, mb: 10 } : {}}
        >
          <div
            class={isMobile ? "vl-cae-mobile" : "vl-cae"}
            onClick={goToCAERef}
          >
            <p
              style={
                isMobile
                  ? theme.paragraphHeadersMobile.root
                  : theme.paragraphHeaders.root
              }
            >
              {" "}
              CAE{" "}
            </p>
            <p
              style={
                isMobile
                  ? theme.subHeaderHomeMobile.root
                  : theme.subHeaderHomeDesktop.root
              }
            >
              Computer Aided <br /> Engineering
            </p>
          </div>

          <div class={isMobile ? "vl-de-mobile" : "vl-de"} onClick={goToDEREef}>
            <p
              style={
                isMobile
                  ? theme.paragraphHeadersMobile.root
                  : theme.paragraphHeaders.root
              }
            >
              {" "}
              DE{" "}
            </p>
            <p
              style={
                isMobile
                  ? theme.subHeaderHomeMobile.root
                  : theme.subHeaderHomeDesktop.root
              }
            >
              Digital <br /> Engineering
            </p>
          </div>

          <div class={isMobile ? "vl-ec-mobile" : "vl-ec"} onClick={goToECRef}>
            <p
              style={
                isMobile
                  ? theme.paragraphHeadersMobile.root
                  : theme.paragraphHeaders.root
              }
            >
              {" "}
              EC{" "}
            </p>
            <p
              style={
                isMobile
                  ? theme.subHeaderHomeMobile.root
                  : theme.subHeaderHomeDesktop.root
              }
            >
              Engineering <br /> Consulting
            </p>
          </div>
        </Grid>
      </div>
      {/* ) */}
      {/* } */}
    </Grid>
  );
}

export default Home;
