import React from "react";
import { Grid } from "@mui/material";
import Hover from "./Hover.json";
import HoverMobile from "./HoverMobile.json";
import theme from "../../Styles/theme";
import { isMobile, isBrowser } from "react-device-detect";
function Timeline({ TimelineRef }) {
  return (
    <>
      <Grid
        container
        sx={{ mt: 2, mb: 4, width: "100%" }}
        direction="row"
        justifyContent="left"
        alignItems="left"
        ref={TimelineRef}
        id="TimelineRef"
      >
        <Grid item lg={1} />

        {isBrowser ? (
          <>
            <Grid item lg={1} align="left">
              <p style={theme.aboutSubHeadingsMobile.root}>
                <b>EVOLUTION</b>
              </p>
            </Grid>
            <Grid item lg={11} />
            <Grid item align="center" xs={12} lg={12}>
              <div>
                {Hover.map((hover) => (
                  <>
                    <div class="imageBox">
                      <div class="imageInn">
                        <img
                          src={require(`./Images/${hover.img}`)}
                          width="75%"
                          position="absolute"
                          alt=""
                        ></img>
                      </div>
                      <div class="hoverImg">
                        <img
                          src={require(`./Images/${hover.hover}`)}
                          width="75%"
                          position="absolute"
                          alt=""
                        ></img>
                      </div>
                      {hover.noDiv ? <></> : <div class={"vl-hover"} />}
                    </div>
                  </>
                ))}
              </div>
            </Grid>
            <Grid item lg={1} />
          </>
        ) : (
          <>
            <Grid item align="center" xs={12}>
              <p style={theme.aboutSubHeadingsMobile.sub}>Evolution</p>
            </Grid>
            <Grid item align="center" xs={12}>
              <div>
                {HoverMobile.map((hover) => (
                  <>
                    <div class="imageBox">
                      <div class="imageInn">
                        <img
                          src={require(`./Images/${hover.img}`)}
                          width="70%"
                          position="absolute"
                          alt=""
                        ></img>
                      </div>
                      <div class="hoverImg">
                        <img
                          src={require(`./Images/${hover.hover}`)}
                          width="70%"
                          position="absolute"
                          alt=""
                        ></img>
                      </div>
                      {hover.noDiv ? <></> : <div class={"vl-hover-mobile"} />}
                    </div>
                  </>
                ))}
              </div>
            </Grid>
            <Grid item xs={0.5} />
          </>
        )}
      </Grid>
    </>
  );
}

export default Timeline;
