import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { concatPathWithParams } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // baseUrl: `/`,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),

  tagTypes: [
    "type_catagories",
    "privacy_policy"
  ],
  endpoints: (builder) => ({    

    getAppTypeCatagories: builder.query({
      query: (request) => concatPathWithParams(`${process.env.REACT_APP_DEM_URL}/quality_control/questions/`, request.queryParams),
      providesTags: ["type_catagories"],
    }),
    getPrivacyPolicy: builder.query({
      query: () => `${process.env.REACT_APP_SECRET_API}/contact/privacypolicy/`,
      providesTags: ["privacy_policy"],
    }),
    getQuestionTemplates: builder.mutation({
      query: (request) => ({
        url: concatPathWithParams(`${process.env.REACT_APP_DEM_URL}/quality_control/questions/`, request.queryParams),
        method: "GET",
        body: request.body,
      }),
      providesTags: ["question_templates"],
    }),
    newTicket: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_DEM_URL}/quality_control/tickets/`,
        method: "POST",
        body: request.body,
      }),
    }),
    sendEmail: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_SECRET_API}/contact/sendEmail/`,
        method: "POST",
        body: request.body,
      }),
    }),

  }),
});

export const { 
  
  useGetAppTypeCatagoriesQuery,
  useGetPrivacyPolicyQuery,
  useGetQuestionTemplatesMutation,
  useNewTicketMutation,
  useSendEmailMutation
  
} = apiSlice;
