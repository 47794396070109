import React, { useRef } from "react";
import theme from "../Styles/theme";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ryan from "../Images/PP/Desktop/Ryan.png";
import ruben from "../Images/PP/Desktop/Ruben.png";
import charl from "../Images/PP/Desktop/Charl.png";
import etienne from "../Images/PP/Desktop/Etienne.png";
import adam from "../Images/PP/Desktop/Adam.png";
import quintin from "../Images/PP/Desktop/Quintin.png";
import dylan from "../Images/PP/Desktop/Dylan.png";
import raeesa from "../Images/PP/Desktop/Raeesa.png";
import johan from "../Images/PP/Desktop/Johan.png";
import yolandi from "../Images/PP/Desktop/Yolandi.png";
import tim from "../Images/PP/Desktop/Tim.png";
import jared from "../Images/PP/Desktop/Jared.png";
import { isMobile, isBrowser } from "react-device-detect";
import AboutSVG from "./AboutSVG";
import Timeline from "./Timeline/Timeline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";

function About() {
  let TeamRef = useRef();
  let TimelineRef = useRef();
  let VisionRef = useRef();
  let MissionRef = useRef();
  let ValuesRef = useRef();
  let CultureRef = useRef();

  const dispatch = useDispatch();
  return (
    <>
      <AboutSVG />
      <AboutScrolls
        TeamRef={TeamRef}
        TimelineRef={TimelineRef}
        VisionRef={VisionRef}
        MissionRef={MissionRef}
        ValuesRef={ValuesRef}
        CultureRef={CultureRef}
      />
      {isMobile ? (
        <>
          <GridsForParagraphs
            text={
              "'With unwavering ambition to pioneer groundbreaking solutions, a visionary drive to shape the technological landscape, and an unparalleled passion for excellence, we strive to redefine industries through innovative software development, design, and engineering.'"
            }
          />
        </>
      ) : (
        <DesktopMainParagraph />
      )}
      <Button
        variant="contained"
        startIcon={<MailOutlineIcon />}
        onClick={() => dispatch(setContactUsModalOpen(true))}
        sx={{ mb: 2 }}
      >
        Get in touch
      </Button>
      <TheTeam TeamRef={TeamRef} />
      <Timeline TimelineRef={TimelineRef} />
      <TheVision VisionRef={VisionRef} />
      <TheMission MissionRef={MissionRef} />
      <TheCulture CultureRef={CultureRef} />
      <TheValues ValuesRef={ValuesRef} />
    </>
  );
}

function ButtonsForScrolls({ buttonName, onScroll }) {
  return buttonName !== "Vision" ? (
    <div class={isMobile ? "divider-mobile" : "divider-desktop"}>
      <Button
        style={theme.aboutScrollsMobile.root}
        variant="text"
        onClick={onScroll}
      >
        {buttonName}
      </Button>
    </div>
  ) : (
    <div class={isMobile ? "divider-mobile-vision" : "divider-desktop"}>
      <Button
        style={theme.aboutScrollsMobile.root}
        variant="text"
        onClick={onScroll}
      >
        {buttonName}
      </Button>
    </div>
  );
}
function AboutScrolls({
  TeamRef,
  TimelineRef,
  VisionRef,
  MissionRef,
  ValuesRef,
  CultureRef,
}) {
  const goToTeamRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: TeamRef.current.offsetTop - 100,
    });
  };
  const goToTimelineRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: TimelineRef.current.offsetTop - 100,
    });
  };
  const goToVisionRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: VisionRef.current.offsetTop - 100,
    });
  };

  const goToMissionRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: MissionRef.current.offsetTop - 100,
    });
  };
  const goToValuesRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: ValuesRef.current.offsetTop - 100,
    });
  };

  const goToCultureRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: CultureRef.current.offsetTop - 100,
    });
  };
  return (
    <>
      <Grid
        container
        sx={{ mt: 6, mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid lg={3} />
        <Grid item xs={4} sm={4} lg={1}>
          <ButtonsForScrolls buttonName={"Team"} onScroll={goToTeamRef} />
        </Grid>
        <Grid item xs={4} sm={4} lg={1}>
          <ButtonsForScrolls
            buttonName={"Evolution"}
            onScroll={goToTimelineRef}
          />
        </Grid>
        <Grid item xs={4} sm={4} lg={1}>
          <ButtonsForScrolls buttonName={"Vision"} onScroll={goToVisionRef} />
        </Grid>
        <Grid item xs={4} sm={4} lg={1}>
          <ButtonsForScrolls buttonName={"Mission"} onScroll={goToMissionRef} />
        </Grid>

        <Grid item xs={4} sm={6} lg={1}>
          <ButtonsForScrolls buttonName={"Culture"} onScroll={goToCultureRef} />
        </Grid>
        <Grid item xs={4} sm={6} lg={1}>
          <div
            class={isMobile ? "divider-mobile-vision" : "divider-desktop-none"}
          >
            <Button
              style={theme.aboutScrollsMobile.root}
              variant="text"
              onClick={goToValuesRef}
            >
              Values
            </Button>
          </div>
        </Grid>
        <Grid lg={3} />
      </Grid>
    </>
  );
}

function TheTeam({ TeamRef }) {
  return (
    <div
      ref={TeamRef}
      id="TeamRef"
      style={
        isMobile ? { marginTop: "20px", height: "100%" } : { height: "100%" }
      }
    >
      {isMobile ? <GridsForParagraphs header={"TEAM"} /> : <></>}
      <Grid
        container
        sx={{ p: 2, mb: 4, width: "100%" }}
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {isBrowser ? (
          <>
            <Grid item lg={2} />
            <Grid item lg={2}>
              <p style={theme.aboutSubHeadingsMobile.root}> TEAM</p>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <GridsForTeamProfiles
          img={ryan}
          name={"Ryan Dyer"}
          jobDescription={"Founder and Group CEO"}
        />
        <GridsForTeamProfiles
          img={charl}
          name={"Charl Maré"}
          jobDescription={
            "Managing Director of Cauchy Computer-Aided Engineering"
          }
        />
        <GridsForTeamProfiles
          img={ruben}
          name={"Ruben Adriaanse"}
          jobDescription={"Managing Director of Cauchy Digital Engineering"}
        />
        {isBrowser ? (
          <>
            <Grid item lg={2} />
            <Grid item lg={2} />
          </>
        ) : (
          <></>
        )}
        <GridsForTeamProfiles
          img={etienne}
          name={"Etienne vd Linde"}
          jobDescription={"Group CTO"}
        />

        <GridsForTeamProfiles
          img={raeesa}
          name={"Raeesa Aboo"}
          jobDescription={"Computer Scientist and Software Engineer"}
        />
        <GridsForTeamProfiles
          img={quintin}
          name={"Quintin de Jongh"}
          jobDescription={"Computer-Aided Engineering Consultant"}
        />

        <GridsForTeamProfiles
          img={dylan}
          name={"Dylan Yesson"}
          jobDescription={"Digital Engineering Consultant"}
        />

        {isBrowser ? (
          <>
            <Grid item lg={2} />
            <Grid item lg={2} />
          </>
        ) : (
          <></>
        )}
        <GridsForTeamProfiles
          img={johan}
          name={"Johan Greyling"}
          jobDescription={"Lead DevOps Engineer"}
        />
        <GridsForTeamProfiles
          img={tim}
          name={"Tim Downing"}
          jobDescription={"Lead Digital Designer"}
        />
        <GridsForTeamProfiles
          img={yolandi}
          name={"Yolandi Geyer"}
          jobDescription={"Business Development Manager"}
        />
        <GridsForTeamProfiles
          img={adam}
          name={"Adam Bouachir"}
          jobDescription={"Director of Sales and Marketing"}
        />
        {isBrowser ? (
          <>
            <Grid item lg={2} />
          </>
        ) : (
          <></>
        )}
        <GridsForTeamProfiles
          img={jared}
          name={"Jared Wingate-Pearse"}
          jobDescription={"Group CFO"}
        />
      </Grid>
    </div>
  );
}

function TheVision({ VisionRef }) {
  return (
    <div
      ref={VisionRef}
      id="VisionRef"
      style={
        isMobile ? { marginTop: "20px", height: "100%" } : { height: "100%" }
      }
    >
      <GridsForParagraphs
        header={" VISION"}
        text={
          "At the core of our team's ethos lies a compelling vision—one where technological advancements are dedicated to serving the best interests of both end-users and the environment. Envisioning a harmonious space where skills and knowledge are openly shared, we foster a collaborative spirit to drive future innovations."
        }
      />
    </div>
  );
}

function TheMission({ MissionRef }) {
  return (
    <div
      ref={MissionRef}
      id="MissionRef"
      style={
        isMobile ? { marginTop: "20px", height: "100%" } : { height: "100%" }
      }
    >
      <GridsForParagraphs
        header={" MISSION"}
        text={
          "Our team is committed to fostering long-term and value-driven relationships by crafting innovative solutions that empower businesses and people alike to unlock their full potential through the transformative power of technology."
        }
      />
    </div>
  );
}

function TheCulture({ CultureRef }) {
  return (
    <div
      ref={CultureRef}
      id="CultureRef"
      style={
        isMobile ? { marginTop: "20px", height: "100%" } : { height: "100%" }
      }
    >
      <GridsForParagraphs
        header={" CULTURE"}
        text={
          "We are a team of forward-thinking individuals who collectively believe that people perform their best when they are healthy and happy. Our team, our community and the environment are essential to us, we continuously strive to provide enjoyable and meaningful engagements with those around us. We emphasize career growth and continuous learning to develop our skills and, ultimately, the products, services and solutions we offer. Our flat organizational structure ensures clear and concise communication, and everyone's views are valued equally. We believe we can achieve anything we set our minds to by working together."
        }
      />
    </div>
  );
}

function TheValues({ ValuesRef }) {
  return (
    <div
      ref={ValuesRef}
      id="ValuesRef"
      style={
        isMobile ? { marginTop: "20px", height: "100%" } : { height: "100%" }
      }
    >
      <GridsForParagraphs
        header={"  VALUES"}
        text={
          "We put ourselves in your shoes to understand the challenges you face, then we act in your best interest to ensure your challenges are addressed directly and sustainably. Our dedication is embodied in our four core values, which serve as guiding principles for our work and interactions."
        }
      />
      <Grid
        container
        sx={{ mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={0.5} lg={3} />

        <Grid item xs={11} lg={7}>
          <p
            style={
              isMobile
                ? theme.aboutParagraphsMobile.root
                : theme.aboutParagraphsMobile.root
            }
          >
            Our guiding Principles for your Success:
            <ul>
              <li>
                Get Things Done: We turn ideas into reality with the right
                amount of work and effort. If it can be done, we can do it.
              </li>
              <li>
                Own the Outcome: No excuses. Your success is our reputation. We
                take responsibility for our work, proudly putting our name on
                it.
              </li>
              <li>
                Learn Deeply: We're more than just vendors. We analyse your
                business processes, making your goals our purpose.
              </li>
              <li>
                Stay Positive: Obstacles are inevitable, but we always find a
                way to move forward and enjoy the journey
              </li>
            </ul>
          </p>
        </Grid>

        <Grid item xs={0.5} lg={2} />
      </Grid>
    </div>
  );
}

function GridsForParagraphs({ header, text }) {
  return (
    <>
      {isMobile ? (
        <p style={theme.aboutSubHeadingsMobile.sub}>
          <b>{header}</b>
        </p>
      ) : (
        <></>
      )}
      <Grid
        container
        sx={{ mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={0.5} lg={1} />

        {isBrowser ? (
          <>
            <Grid item lg={1} align="right">
              <p style={theme.aboutSubHeadingsMobile.root}>{header}</p>
            </Grid>
            <Grid item lg={1} />
          </>
        ) : (
          <></>
        )}
        {header == "TEAM" ? (
          <></>
        ) : (
          <Grid item xs={11} lg={8}>
            <p
              style={
                isMobile
                  ? theme.aboutParagraphsMobile.root
                  : theme.aboutParagraphsMobile.root
              }
            >
              {text}
            </p>
          </Grid>
        )}
        <Grid item xs={0.5} lg={1} />
      </Grid>
    </>
  );
}

function DesktopMainParagraph() {
  return (
    <>
      <Grid
        container
        sx={{ mt: 2, mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={3} />

        <Grid item lg={6}>
          <p style={theme.aboutParagraphsDesktop.root}>
            "With unwavering ambition to pioneer groundbreaking solutions, a
            visionary drive to shape the technological landscape, and an
            unparalleled passion for excellence, we strive to redefine
            industries through innovative design development and engineering"
          </p>
        </Grid>
        <Grid item lg={3} />
      </Grid>
    </>
  );
}

function GridsForTeamProfiles({ img, name, jobDescription }) {
  return (
    <>
      <Grid item xs={6} lg={2}>
        <img src={img} width="50%"></img>
        <p
          style={
            isMobile
              ? theme.theTeamHeadingsMobile.root
              : theme.theTeamHeadingsMobile.root
          }
        >
          {name}
        </p>
        <p
          style={
            isMobile
              ? theme.theTeamSubHeadingsMobile.root
              : theme.theTeamSubHeadingsMobile.root
          }
        >
          {jobDescription}
        </p>
      </Grid>
    </>
  );
}
export default About;
