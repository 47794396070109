import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./Features/api/apiSlice";

import appReducer from "./Components/appSlice";
import notificationsReducer from "./Components/notificationsSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    app: appReducer,
    notifications: notificationsReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
