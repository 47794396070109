import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from 'dompurify'
//Redux
import { useGetPrivacyPolicyQuery } from "../Features/api/apiSlice";

export default function PrivacyPolicy() {
  let navigate = useNavigate();
  const { data: privacy_policy_html, isSuccess: isGetPrivacyPolicySuccess  } = useGetPrivacyPolicyQuery();

  if(isGetPrivacyPolicySuccess){
   
    return (
      <>
      <Grid container     spacing={{ xs: 1, sm: 2, md: 3 }} 
      columns={{ xs: 2, sm: 4, md: 8 }}   
      direction="row"
     
      alignItems="left">
   
          <Grid item xs={12} style={{marginBottom:5}} align="left">
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(privacy_policy_html.PrivacyPolicy, {WHOLE_DOCUMENT: true})}} />
          </Grid>
      </Grid>
      </>
    );
  } else {
    return (
      <></>
    )
  }
  
}