import React from "react";
import theme from "../../Components/Styles/theme";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import ratings from "./Ratings.json";

function DivsForClientRatings({ rating, name, img }) {
  return (
    <div class="full-page-width">
      <div class="center-content-container">
        <div class="content-container">
          <img src={require(`../Images/${img}`)} width="10px"></img>
        </div>
        <Typography
          style={{ color: "#fff", lineHeight: "normal" }}
          variant={isMobile ? "body2" : "h6"}
        >
          {rating}
          <br />
          <br /> - {name}
        </Typography>
      </div>
    </div>
  );
}
export default function ClientRatings() {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Grid item align="center" xs={12} md={12}>
          <p
            style={
              isMobile
                ? theme.homeHeadingsMobile.root
                : theme.homeHeadingsDesktop.root
            }
          >
            Testimonials
          </p>
          <br />
        </Grid>
        <Grid item xs={1} lg={1} />
        <Grid item align="center" xs={10} md={12} lg={10}>
          {/* <div className="slider-container"> */}
          <Carousel
            showStatus={false}
            showIndicators={false}
            centerMode={true}
            centerSlidePercentage={100}
            showThumbs={false}
          >
            {ratings.map((rating, index) => (
              <DivsForClientRatings
                key={index}
                rating={rating.rating}
                name={rating.name}
                img={rating.img}
              />
            ))}
          </Carousel>
          {/* </div> */}
        </Grid>
        <Grid item xs={1} lg={1} />
      </Grid>
    </>
  );
}
