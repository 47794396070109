import React, { useRef } from "react";
import { Divider, Button } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import theme from "../JeanStyles/theme";
import CAEMobile from "../Images/CAE/CAEMobile.png";
import CAEDesktop from "../Images/CAE/CAEDesktop.png";
import { isMobile, isBrowser } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";

import Grid from "@mui/material/Grid";

export default function ComputerAidedEngineeringPage() {
  const dispatch = useDispatch();
  let CADRef = useRef();
  let CFDRef = useRef();
  let FEARef = useRef();

  const goToCADRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: CADRef.current.offsetTop - 100,
    });
  };
  const goToCFDRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: CFDRef.current.offsetTop - 100,
    });
  };
  const goToFEARef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: FEARef.current.offsetTop - 100,
    });
  };
  return (
    <div>
      <TopDescription
        goToCADRef={goToCADRef}
        goToCFDRef={goToCFDRef}
        goToFEARef={goToFEARef}
      />

      {isMobile && <ComputerAidedDesignMobile setRef={CADRef} />}
      {isBrowser && <ComputerAidedDesignBrowser setRef={CADRef} />}

      {isMobile && <ComputationalFluidDynamicsMobile setRef={CFDRef} />}
      {isBrowser && <ComputationalFluidDynamicsBrowser setRef={CFDRef} />}

      {isMobile && <FiniteElementAnalysisMobile setRef={FEARef} />}
      {isBrowser && <FiniteElementAnalysisBrowser setRef={FEARef} />}
    </div>
  );
}

function TopDescription({ goToCADRef, goToCFDRef, goToFEARef }) {
  const dispatch = useDispatch();

  return (
    <div>
      <Grid
        container
        sx={{ mt: 2, mb: 4, width: "100%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            background: `url(${isMobile ? CAEMobile : CAEDesktop}) `,
            ...(isMobile
              ? theme.caeTopMobile.background.image
              : theme.caeTopBrowser.background.image),
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.headings.main
                  : theme.caeTopBrowser.headings.main
              }
            >
              CAE
            </p>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.headings.sub
                  : theme.caeTopBrowser.headings.sub
              }
            >
              Computer-Aided Engineering
            </p>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.paragraphs.main
                  : theme.caeTopBrowser.paragraphs.main
              }
            >
              Leverage CAE to simulate complex engineering problems to assess
              and improve the performance of a product or system for a wide
              range of industries. With CAE, design, testing and optimisation
              can be performed virtually without needing a physical prototype,
              saving you time and money.
            </p>
          </Grid>
          <Grid />

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ ml: { lg: "20px" }, mb: 2 }}
            style={
              isMobile ? theme.caeTopMobile.align : theme.caeTopBrowser.align
            }
            container
          >
            <div
              class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-left"}
              onClick={goToCADRef}
            >
              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsTop.main
                    : theme.caeTopBrowser.descriptionsTop.main
                }
              >
                CAD
              </p>

              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsBottom.main
                    : theme.caeTopBrowser.descriptionsBottom.main
                }
              >
                Computer-Aided Design
              </p>
            </div>

            <div
              class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-center"}
              onClick={goToCFDRef}
            >
              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsTop.main
                    : theme.caeTopBrowser.descriptionsTop.main
                }
              >
                CFD
              </p>
              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsBottom.main
                    : theme.caeTopBrowser.descriptionsBottom.main
                }
              >
                Computational Fluid Dynamics
              </p>
            </div>

            <div
              class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-right"}
              onClick={goToFEARef}
            >
              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsTop.main
                    : theme.caeTopBrowser.descriptionsTop.main
                }
              >
                FEA
              </p>
              <p
                style={
                  isMobile
                    ? theme.caeTopMobile.descriptionsBottom.main
                    : theme.caeTopBrowser.descriptionsBottom.main
                }
              >
                Finite Element Analysis
              </p>
            </div>
          </Grid>
          <Grid
            item
            align="left"
            xs={12}
            md={12}
            lg={12}
            sx={{
              mt: isBrowser ? 15 : 1,
              ml: { lg: "20px" },
              justifyContent: isMobile ? "center" : "flex-start",
              textAlign: isMobile ? "center" : "left",
            }}
            style={
              isMobile ? theme.caeTopMobile.align : theme.caeTopBrowser.align
            }
          >
            <Button
              variant={"contained"}
              onClick={() => dispatch(setContactUsModalOpen(true))}
              style={
                isMobile
                  ? theme.caeTopMobile.button
                  : theme.caeTopBrowser.button
              }
            >
              <MessageIcon
                style={{ paddingRight: "5px", paddingTop: "5px" }}
              ></MessageIcon>
              LET'S TALK ABOUT YOUR PROJECT
            </Button>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

function ComputerAidedDesignMobile({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid item lg={2} />
        <Grid item xs={10} md={12} lg={12} sx={{ mb: -8 }} container>
          <div class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-left"}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsTop.main
                  : theme.caeTopBrowser.descriptionsTop.main
              }
            >
              CAD
            </p>

            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsBottom.main
                  : theme.caeTopBrowser.descriptionsBottom.main
              }
            >
              Computer-Aided Design
            </p>
          </div>
        </Grid>
        <Grid item lg={12} />
        <Grid item lg={12} />
        <Grid
          item
          xs={12}
          md={12}
          lg={10.5}
          style={
            isMobile
              ? theme.caeMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.main
          }
        >
          <p>
            Spatial three-dimensional (3-D) creation, modification, analysis, or
            optimization of a product or system whether in the design phase or
            already in existence. CAD enables cost-effective design iteration or
            modification through solid model parameterisation ake 3D models to
            2D engineering drawings for manufacturing seamlessly.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Precision in 3D modelling for accurate product representations
            </li>
            <li>Customisable and scalable solutions for various industries</li>
            <li>
              Expertise in creating intricate and detailed design prototypes
            </li>
            <li>
              Reduce the time and cost to take a product idea from concept to
              final design to production
            </li>
            <li>
              Acquire the necessary engineering drawings for use in prototyping
              & production
            </li>
            <li>
              Collaboration with clients to understand design specifications
            </li>
            <li>On-premises or remote consultation for design requirements</li>
            <li>
              Incorporate design for manufacturing into your products to reduce
              production cost and improve quality
            </li>
            <li>
              Obtain 3D CAD renders to visualize your product and use them in
              preproduction marketing
            </li>
            <li>Prevent costly part interference issues during assembly</li>
          </ul>
        </Grid>
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function ComputerAidedDesignBrowser({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ backgroundColor: "#030822", mb: 8 }}
      >
        <Grid item lg={4.5}></Grid>
        <Grid item xs={12} md={12} lg={6.5} align="center">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.headings.main
            }
          >
            Computer-Aided Design
          </p>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={1.5}></Grid>
        <Grid item xs={12} md={12} lg={7.3}>
          <p
            style={
              isMobile
                ? theme.caeMobile.paragraphs.main
                : theme.caeBrowser.paragraphs.second
            }
          >
            Spatial three-dimensional (3-D) creation, modification, analysis, or
            optimization of a product or system whether in the design phase or
            already in existence. CAD enables cost-effective design iteration or
            modification through solid model parameterisation take 3D models to
            2D engineering drawings for manufacturing seamlessly.
          </p>
        </Grid>

        <Grid item xs={12} md={12} lg={1} align="right">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.subHeadings.main
            }
            class={isMobile ? "vl-cae-mobile-services" : "hline-cae-browser-a"}
          >
            CAD
          </p>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={1} />
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Precision in 3D modelling for accurate product representations
            </li>
            <li>Customisable and scalable solutions for various industries</li>
            <li>
              Expertise in creating intricate and detailed design prototypes
            </li>
            <li>
              Reduce the time and cost to take a product idea from concept to
              final design to production
            </li>
            <li>
              Acquire the necessary engineering drawings for use in prototyping
              & production
            </li>
          </ul>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Collaboration with clients to understand design specifications
            </li>
            <li>On-premises or remote consultation for design requirements</li>
            <li>
              Incorporate design for manufacturing into your products to reduce
              production cost and improve quality
            </li>
            <li>
              Obtain 3D CAD renders to visualize your product and use them in
              preproduction marketing
            </li>
            <li>Prevent costly part interference issues during assembly</li>
          </ul>
        </Grid>
        <Grid item lg={1} />
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function ComputationalFluidDynamicsMobile({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid item lg={2} />
        <Grid item xs={10} md={12} lg={12} sx={{ mb: -8 }} container>
          <div class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-left"}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsTop.main
                  : theme.caeTopBrowser.descriptionsTop.main
              }
            >
              CFD
            </p>

            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsBottom.main
                  : theme.caeTopBrowser.descriptionsBottom.main
              }
            >
              Computational Fluid Dynamics
            </p>
          </div>
        </Grid>
        <Grid item lg={12} />
        <Grid item lg={12} />
        <Grid
          item
          xs={12}
          md={12}
          lg={10.5}
          style={
            isMobile
              ? theme.caeMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.main
          }
        >
          <p>
            Simulate how fluids flow as they exist in the real world. CFD can
            simulate complex physics and problems involving gases, liquids,
            solids, particles, or any combination thereof. It provides a means
            of evaluating, visualising, analysing, and predicting the
            performance of a product or system, existing or in development. The
            use of CFD for design and optimisation has increased drastically
            over the years, producing robust and high-quality designs.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Customised CFD solutions for diverse industries and applications
            </li>
            <li>Determine if the design meets the requirements</li>
            <li>
              Characterize (inner workings) of the design from a fluid dynamic
              perspective
            </li>
            <li>Identify and remedy any potential or existing problems</li>
            <li>
              Optimize design e.g. decrease pressure drop, power consumption or
              increase efficiency
            </li>
            <li>
              Test the design’s performance for various operating conditions
            </li>
            <li>
              Evaluate the effect of changes/modifications on your design’s
              performance
            </li>
          </ul>
        </Grid>
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function ComputationalFluidDynamicsBrowser({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid
        container
        direction="row"
        alignItems="start"
        sx={{ backgroundColor: "#030822", mb: 8 }}
        gap={1}
      >
        <Grid item xs={12} lg={1.5} />
        <Grid item xs={12} md={12} lg={9} align="start">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.headings.main
            }
          >
            Computational Fluid Dynamics
          </p>
        </Grid>
        <Grid item xs={12} lg={1.5}></Grid>
        <Grid item xs={12} md={12} lg={1} align="start">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.subHeadings.second
            }
            class={isMobile ? "vl-cae-mobile-services" : "hline-cae-browser-a"}
          >
            CFD
          </p>
        </Grid>

        <Grid item xs={12} md={12} lg={6} align="center">
          <p
            style={
              isMobile
                ? theme.caeMobile.paragraphs.main
                : theme.caeBrowser.paragraphs.second
            }
          >
            Simulate how fluids flow as they exist in the real world. CFD can
            simulate complex physics and problems involving gases, liquids,
            solids, particles, or any combination thereof. It provides a means
            of evaluating, visualising, analysing, and predicting the
            performance of a product or system, existing or in development. The
            use of CFD for design and optimisation has increased drastically
            over the years, producing robust and high-quality designs.
          </p>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={1} />
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Customised CFD solutions for diverse industries and applications
            </li>
            <li>Determine if the design meets the requirements</li>
            <li>
              Characterize (inner workings) of the design from a fluid dynamic
              perspective
            </li>
            <li>Identify and remedy any potential or existing problems</li>
          </ul>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Optimize design e.g. decrease pressure drop, power consumption or
              increase efficiency
            </li>
            <li>
              Test the design’s performance for various operating conditions
            </li>
            <li>
              Evaluate the effect of changes/modifications on your design’s
              performance
            </li>
          </ul>
        </Grid>
        <Grid item lg={1} />
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function FiniteElementAnalysisMobile({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid item lg={2} />
        <Grid item xs={10} md={12} lg={12} sx={{ mb: -8 }} container>
          <div class={isMobile ? "vl-cae-mobile-services" : "vl-top-cae-left"}>
            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsTop.main
                  : theme.caeTopBrowser.descriptionsTop.main
              }
            >
              FEA
            </p>

            <p
              style={
                isMobile
                  ? theme.caeTopMobile.descriptionsBottom.main
                  : theme.caeTopBrowser.descriptionsBottom.main
              }
            >
              Finite Element Analysis
            </p>
          </div>
        </Grid>
        <Grid item lg={12} />
        <Grid item lg={12} />
        <Grid
          item
          xs={12}
          md={12}
          lg={10.5}
          style={
            isMobile
              ? theme.caeMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.main
          }
        >
          <p>
            Predict how a product or system, existing or in development, will
            react and perform under real-world conditions. Taking into
            consideration forces, vibrations, heat, fluid flows, and motion. FEA
            shows whether a product or system will fail, or wear, and assesses
            if it operates as intended. It reduces or eliminates the need for
            physical prototypes by enabling one to run virtual experiments to
            optimise a design cost-effectively.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Mastery in structural simulations for optimal product performance
            </li>
            <li>
              In-depth analysis of structural components to ensure durability
            </li>
            <li>Customised FEA solutions for diverse engineering challenges</li>
            <li>Application of FEA to predict and improve product behaviour</li>
            <li>
              Collaborative approach to incorporate client feedback in
              simulations
            </li>
            <li>Identify and address potential or existing problems</li>
            <li>Reduce weight without sacrificing strength</li>
            <li>
              Determine if the design will fail due to real-world conditions
            </li>
            <li>Increase the strength and lifespan of your design</li>
            <li>Test design’s performance for a range of materials</li>
            <li>
              Evaluate the effect of changes on the performance of your design
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}
function FiniteElementAnalysisBrowser({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ backgroundColor: "#030822", mb: 8 }}
      >
        <Grid item lg={4.5}></Grid>
        <Grid item xs={12} md={12} lg={6.5} align="center">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.headings.main
            }
          >
            Finite Element Analysis
          </p>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={1.5}></Grid>
        <Grid item xs={12} md={12} lg={7.2} align="left">
          <p
            style={
              isMobile
                ? theme.caeMobile.paragraphs.main
                : theme.caeBrowser.paragraphs.second
            }
          >
            Predict how a product or system, existing or in development, will
            react and perform under real-world conditions. Taking into
            consideration forces, vibrations, heat, fluid flows, and motion. FEA
            shows whether a product or system will fail, or wear, and assesses
            if it operates as intended. It reduces or eliminates the need for
            physical prototypes by enabling one to run virtual experiments to
            optimise a design cost-effectively.
          </p>
        </Grid>
        <Grid item xs={12} md={12} lg={1} align="right">
          <p
            style={
              isMobile
                ? theme.caeMobile.headings.main
                : theme.caeBrowser.subHeadings.main
            }
            class={isMobile ? "vl-cae-mobile-services" : "hline-cae-browser-a"}
          >
            FEA
          </p>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={1} />
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>
              Mastery in structural simulations for optimal product performance
            </li>
            <li>
              In-depth analysis of structural components to ensure durability
            </li>
            <li>Customised FEA solutions for diverse engineering challenges</li>
            <li>Application of FEA to predict and improve product behaviour</li>
            <li>
              Collaborative approach to incorporate client feedback in
              simulations
            </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.deMobile.bullets.main
              : theme.deBrowser.bullets.main
          }
        >
          <ul>
            <li>Identify and address potential or existing problems</li>
            <li>Reduce weight without sacrificing strength</li>
            <li>
              Determine if the design will fail due to real-world conditions
            </li>
            <li>Increase the strength and lifespan of your design</li>
            <li>Test design’s performance for a range of materials</li>
            <li>
              Evaluate the effect of changes on the performance of your design
            </li>
          </ul>
        </Grid>{" "}
        <Grid item lg={1} />
      </Grid>
    </div>
  );
}
