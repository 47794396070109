import React from "react";
import { ReactComponent as DesktopIcon } from "../Images/background/About_Integration_Dekstop.svg";
import { ReactComponent as MobileIcon } from "../Images/background/About_Intergation_Mobile.svg";
import { isBrowser } from "react-device-detect";
import theme from "../../Components/Styles/theme";

export default function AboutSVG() {
  return (
    <>
      <div style={{ marginTop: "120px" }}>
        <p
          style={
            isBrowser
              ? theme.aboutHeadingDesktop.root
              : theme.aboutHeadingsMobile.root
          }
        >
          Our Purpose
        </p>
        {isBrowser ? <DesktopIcon /> : <MobileIcon />}
      </div>
    </>
  );
}
