import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";
import { ReactComponent as SvgFile } from "./Images/navLogo.svg";
import { ReactComponent as SvgFile2 } from "./Images/navLogoDesktop.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import drawerImg from "./Images/background/drawer.png";

import {
  Nav,
  NavExpanded,
  Bars,
  NavMenu,
  NavBtn,
  ImgLink,
  NavLink,
  NavMenuFlex,
  NavBtn2,
  NavBtn3,
} from "./NavBarElements";
import homeMobile from "./Images/background/BG-TopNav-Mobile.png";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
//Redux
import {
  setOpenServices,
  getOpenServices,
  setWhatsappModalOpen,
} from "./appSlice";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { setContactUsModalOpen } from "./appSlice";
import theme from "./Styles/theme";
const drawerWidth = 240;
//const link = "https" + ":" + "//www.linkedin.com/company/cauchy-consult/";

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();
  const url = "https://www.linkedin.com/company/cauchy-consult/";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {}, [getOpenServices]);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", bgcolor: "#152641" }}
    >
      <Box
        sx={{
          bgcolor: "#152641",
          height: 50,
          width: 100,
          maxHeight: { xs: 50, md: 100 },
          maxWidth: { xs: 100, md: 200 },
        }}
      />
      <Divider />
      <nav aria-label="main mailbox folders">
        <List sx={{ mb: 3 }}>
          <ListItem
            style={{ color: "#fff" }}
            component={NavLink}
            to="/"
            reloadDocument
            sx={{ mb: -1 }}
          >
            <ListItemButton>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            component={NavLink}
            to="/our-purpose"
            reloadDocument
            sx={{ mb: -1 }}
          >
            <ListItemButton>
              <ListItemText primary="Our Purpose" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            component={NavLink}
            to="/our-services/computer-aided-engineering"
            reloadDocument
            sx={{ mb: -1 }}
          >
            <ListItemButton>
              <ListItemText primary="Computer-Aided Engineering" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            component={NavLink}
            to="/our-services/digital-engineering"
            reloadDocument
            sx={{ mb: -1 }}
          >
            <ListItemButton>
              <ListItemText primary="Digital Engineering" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            component={NavLink}
            to="/our-services/engineering-consulting"
            reloadDocument
          >
            <ListItemButton>
              <ListItemText primary="Engineering Consulting" />
            </ListItemButton>
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            onClick={() => dispatch(setContactUsModalOpen(true))}
          >
            <ListItemButton>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <a style={{ "text-decoration": "none" }} target="_blank" href={url}>
              <LinkedInIcon
                textAlign="left"
                fontSize="medium"
                sx={{
                  color: "#fff",
                  p: 1,
                }}
                onClick={url}
              />
            </a>
            <a style={{ "text-decoration": "none" }} target="_blank">
              <WhatsAppIcon
                fontSize="medium"
                sx={{
                  color: "#fff",
                  p: 1,
                }}
                onClick={() => dispatch(setWhatsappModalOpen(true))}
              />
            </a>

            <MailIcon
              variant="contained"
              fontSize="medium"
              sx={{
                color: "#fff",
                p: 1,
              }}
              onClick={() => dispatch(setContactUsModalOpen(true))}
            />
          </ListItem>
        </List>
      </nav>
      <img src={drawerImg} width="100%" />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const item = {
    projurl: "https://www.linkedin.com/company/cauchy-consult/",
  };
  return (
    <>
      {console.log(useSelector(getOpenServices))}
      <Box>
        {useSelector(getOpenServices) == false ? (
          <Nav>
            <NavComponents handleDrawerToggle={handleDrawerToggle} />
          </Nav>
        ) : (
          <NavExpanded>
            <NavComponents handleDrawerToggle={handleDrawerToggle} />
          </NavExpanded>
        )}
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                backgroundColor: "#152641",
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

function NavComponents({ handleDrawerToggle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const drop = Boolean(anchorEl);
  const dropClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dropClose = () => {
    setAnchorEl(null);
  };
  const url = "https://www.linkedin.com/company/cauchy-consult/";
  const whatsapp = "https://wa.me/message/MKQF3S3FNOS4E1";
  const whatsappUK = "https://wa.me/message/GTFI3DNGQVLOH1";

  return (
    <>
      <ImgLink to="/" reloadDocument>
        {isBrowser ? (
          <SvgFile2 width={"200px"} height={"50%"} />
        ) : (
          <SvgFile width={"80%"} height={"50%"} />
        )}
      </ImgLink>

      {useSelector(getOpenServices) == true ? (
        <NavMenuFlex>
          <GeneralMenu />
        </NavMenuFlex>
      ) : (
        <NavMenu>
          <GeneralMenu />
        </NavMenu>
      )}
      <NavBtn>
        <MailIcon
          variant="contained"
          fontSize="large"
          sx={{
            color: "#fff",
            "&:hover": {
                       
              cursor: "pointer",
            },
          }}
          onClick={() => dispatch(setContactUsModalOpen(true))}
        />
      </NavBtn>

      <NavBtn2>
        <a style={{ "text-decoration": "none",  }} target="_blank">
          <WhatsAppIcon
            fontSize="large"
            sx={{
              color: "#fff",
              "&:hover": {
                       
                cursor: "pointer",
              },
            }}
            onClick={() => dispatch(setWhatsappModalOpen(true))}
          />
        </a>
      </NavBtn2>

      <NavBtn3>
        <a style={{ "text-decoration": "none" }} target="_blank" href={url}>
          <LinkedInIcon
            fontSize="large"
            sx={{
              color: "#fff",
            }}
            onClick={url}
          />
        </a>
      </NavBtn3>

      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <Bars />
      </IconButton>
    </>
  );
}

function ServicesNavMenu() {
  return (
    <div style={{ marginTop: "2px", color: "#5C85D1" }}>
      <Button>
        <NavLink
          reloadDocument
          to="/our-services/computer-aided-engineering"
          activeStyle
        >
          Computer-Aided Engineering
        </NavLink>
      </Button>
      •
      <Button>
        <NavLink
          reloadDocument
          to="/our-services/digital-engineering"
          activeStyle
        >
          Digital Engineering
        </NavLink>
      </Button>
      •
      <Button>
        <NavLink
          reloadDocument
          to="/our-services/engineering-consulting"
          activeStyle
        >
          Engineering Consulting
        </NavLink>
      </Button>
    </div>
  );
}

function GeneralMenu() {
  const dispatch = useDispatch();
  return (
    <div>
      <Button id="demo-positioned-button">
        <NavLink reloadDocument to="/our-purpose" activeStyle>
          Our Purpose
        </NavLink>
      </Button>
      {useSelector(getOpenServices) == false ? (
        <Button
          sx={{ color: "#fff" }}
          onClick={() => dispatch(setOpenServices(true))}
        >
          Our Services
          <ArrowDropDownIcon />
        </Button>
      ) : (
        <Button
          sx={{ color: "#fff" }}
          onClick={() => dispatch(setOpenServices(false))}
        >
          Our Services <ArrowDropUpIcon />
        </Button>
      )}

      <Button
        id="demo-positioned-button"
        style={{ color: "#fff" }}
        onClick={() => dispatch(setContactUsModalOpen(true))}
      >
        CONTACT US
      </Button>
      {useSelector(getOpenServices) == true ? <ServicesNavMenu /> : <></>}
    </div>
  );
}
export default Navbar;
