import './App.css';
import * as React from "react";
import MainRouter from './Components/MainRouter';
import { BrowserRouter, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "@mui/material/styles";
import theme from './Components/theme';
import Dialog from '@mui/material/Dialog';

import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
}

function App() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="App">
      <Helmet>
        <style>{'body { background-color: #030822; }'}</style>

      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>


            <MainRouter />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
