import { createTheme } from "@mui/material/styles";
// import createBreakpoints from '@mui/material/styles';

const theme = createTheme({
  // Styles For EC Page Mobile ----------------------------------------------------------------------------------------------------------
  typography: {
    fontFamily: "Roboto, Roboto Flex",
  },
  svgIcon: {
    width: 250,
    height: 60,
  },

  ecTopMobile: {
    background: {
      image: {
        width: "100%",
        height: "100%",
        paddingBottom: "64px",
      },
    },
    headings: {
      main: {
        color: "#FFA44F",
        textAlign: "start",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "80px ",
        paddingLeft: "20px",
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "20px",
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "20px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
      },
    },
    bullets: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    button: {
      color: "#FFF",
      borderRadius: "4px",
      borderRadius: "4px",
      // paddingRight: "53px",
      // paddingLeft: "53px",
      background: "#FFA44F",
    },
  },

  ecMobile: {
    headings: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingTop: "32px ",
        paddingLeft: "40px",
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "40px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
      },
    },
    bullets: {
      main: {
        paddingLeft: "20px",
        // paddingBottom: "34px",
        color: "#FFF",
        textAlign: "start",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "35px",
        paddingRight: "20px",
      },
    },
  },

  // Styles For EC Page Browser ----------------------------------------------------------------------------------------------------------

  ecTopBrowser: {
    background: {
      image: {
        width: "100%",
        height: 880,
      },
    },
    headings: {
      main: {
        color: "#FFA44F",
        textAlign: "start",
        fontSize: "70px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "140px",
        paddingLeft: "193px",
        paddingBottom: "8px",
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "193px",
        paddingBottom: "20px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "193px",
        paddingRight: "510px",
        paddingBottom: "54px",
      },
    },
    bullets: {
      headers: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "44px",
        paddingLeft: "180px",
        paddingBottom: "195px",
      },
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "44px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        paddingLeft: "180px",
        paddingBottom: "195px",
        marginTop: "32px",
      },
      right: {
        fontFamily: ["Roboto Flex", "serif"].join(","),
        fontSize: "44px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        textAlign: "right",
        color: "#FFF",
      },
    },
    button: {
      color: "#FFF",
      background: "#FFA44F",
      borderRadius: "4px",
      paddingRight: "53px",
      paddingLeft: "53px",
      fontSize: "15px",
      fontWeight: 500,
      marginTop: -120,
    },
  },
  ecBrowser: {
    headings: {
      main: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingTop: "123px ",
        paddingLeft: "194px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "193px",
        // paddingRight: "197px",
        // paddingBottom: "44px",
        lineHeight: "32px",
        marginTop: "30px",
      },
      bottom: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "200px",
        // paddingRight: "197px",
        // paddingBottom: "44px",
        lineHeight: "32px",
      },
    },
    bullets: {
      main: {
        paddingLeft: "194px",
        color: "#FFF",
        textAlign: "start",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "50px",
      },
    },
  },

  // Styles For DE Page Mobile ----------------------------------------------------------------------------------------------------------
  typography: {
    fontFamily: "Roboto, Roboto Flex",
  },
  svgIcon: {
    width: 250,
    height: 60,
  },

  deTopMobile: {
    background: {
      image: {
        width: "100%",
        height: "100%",
        paddingBottom: "64px",
      },
    },
    headings: {
      main: {
        color: "#2196F3",
        textAlign: "start",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "80px ",
        paddingLeft: "20px",
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "20px",
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "20px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
      },
    },
    bullets: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    button: {
      color: "#FFF",
      borderRadius: "4px",
      borderRadius: "4px",
      paddingRight: "53px",
      paddingLeft: "53px",
    },
  },

  deMobile: {
    headings: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingTop: "32px ",
        paddingLeft: "40px",
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "40px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
      },
    },
    bullets: {
      main: {
        paddingLeft: "20px",
        paddingBottom: "34px",
        color: "#FFF",
        textAlign: "start",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "32px",
        paddingRight: "20px",
      },
    },
  },

  // Styles For DE Page Browser ----------------------------------------------------------------------------------------------------------

  deTopBrowser: {
    background: {
      image: {
        width: "100%",
        height: 916,
      },
    },
    headings: {
      main: {
        color: "#2196F3",
        textAlign: "start",
        fontSize: "70px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "140px",
        paddingLeft: "193px",
        paddingBottom: "8px",
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "193px",
        paddingBottom: "20px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "193px",
        paddingRight: "197px",
        paddingBottom: "54px",
      },
    },
    bullets: {
      headers: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        paddingLeft: "180px",
        paddingBottom: "195px",
      },
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "44px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        paddingLeft: "180px",
        paddingBottom: "195px",
      },
      right: {
        fontFamily: ["Roboto Flex", "serif"].join(","),
        fontSize: "44px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        textAlign: "right",
        color: "#FFF",
      },
    },
    button: {
      padding: "8px 22px",
      color: "#FFF",
      borderRadius: "4px",
      paddingRight: "53px",
      paddingLeft: "53px",
      fontSize: "15px",
      fontWeight: 500,
    },
  },
  deBrowser: {
    headings: {
      main: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingTop: "123px ",
        paddingLeft: "194px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingLeft: "193px",
        paddingRight: "197px",
        paddingBottom: "54px",
        lineHeight: "32px",
      },
    },
    bullets: {
      main: {
        paddingLeft: "194px",
        color: "#FFF",
        textAlign: "start",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "50px",
      },
    },
  },

  // Styles For CAE Page Mobile ----------------------------------------------------------------------------------------------------------
  typography: {
    fontFamily: "Roboto, Roboto Flex",
  },
  svgIcon: {
    width: 250,
    height: 60,
  },

  caeTopMobile: {
    background: {
      image: {
        width: "100%",
        height: "100%",
      },
    },
    align: {
      paddingLeft: "18px",
      marginBottom: "30px",
    },
    headings: {
      main: {
        color: "#AB46FA",
        textAlign: "start",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingLeft: "24px",
        marginBottom: -35,
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "40px",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "24px",
        marginBottom: -5,
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "24px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
        marginBottom: 43,
      },
    },
    bullets: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    button: {
      color: "#FFF",
      borderRadius: "4px",
      borderRadius: "4px",

      background: "#AB46FA",
    },
    descriptionsTop: {
      main: {
        color: "#FFF",
        textAlign: "left",
        fontSize: "24px",
        fontStyle: "normal",
        marginTop: -14,
        fontWeight: 300,
      },
    },
    descriptionsBottom: {
      main: {
        color: "#FFF",
        textAlign: "left",
        fontSize: "24px",
        fontStyle: "normal",
        marginTop: -26,
        fontWeight: 600,
      },
    },
  },

  caeMobile: {
    headings: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingTop: "32px ",
        paddingLeft: "40px",
      },
    },
    paragraphs: {
      main: {
        paddingLeft: "40px",
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "20px",
        marginBottom: -20,
      },
    },
    bullets: {
      main: {
        paddingLeft: "20px",
        // paddingBottom: "34px",
        color: "#FFF",
        textAlign: "start",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "35px",
        paddingRight: "20px",
      },
    },
  },
  // Styles For CAE Page Browser ----------------------------------------------------------------------------------------------------------

  caeTopBrowser: {
    background: {
      image: {
        width: "100%",
        height: 930,
      },
    },
    align: {
      paddingLeft: "180px",
      marginBottom: "60px",
    },
    headings: {
      main: {
        color: "#AB46FA",
        textAlign: "start",
        fontSize: "70px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "86px",
        paddingLeft: "200px",
        marginBottom: "8px",
      },
      sub: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "200px",
        marginTop: "0px",
        marginBottom: "20px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingLeft: "200px",
        marginBottom: "56px",
      },
    },
    descriptionsTop: {
      main: {
        color: "#FFF",
        textAlign: "left",
        fontSize: "24px",
        fontStyle: "normal",
        marginTop: -10,
        fontWeight: 300,
        // paddingLeft: "180px",
      },
    },
    descriptionsBottom: {
      main: {
        color: "#FFF",
        textAlign: "left",
        fontSize: "24px",
        fontStyle: "normal",
        marginTop: -10,
        fontWeight: 600,
        // paddingLeft: "180px",
      },
    },
    bullets: {
      main: {
        color: "#FFF",
        textAlign: "right",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
        paddingLeft: "180px",
        paddingBottom: "274px",
      },
    },
    button: {
      background: "#AB46FA",
      color: "#FFF",
      borderRadius: "4px",
      fontSize: "15px",
      fontWeight: 500,
    },
  },
  caeBrowser: {
    headings: {
      main: {
        color: "#FFF",
        // textAlign: "end",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: "0px",
        // paddingRight: "40px",
      },
      second: {
        color: "#FFF",

        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginTop: "0px",
        marginLeft: "35px",
      },
      third: {
        color: "#FFF",
        // textAlign: "end",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: "0px",
        // paddingRight: "40px",
      },
    },
    subHeadings: {
      main: {
        color: "#FFF",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginTop: "0px",
        marginLeft: "35px",
      },
      second: {
        color: "#FFF",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginTop: "0px",
      },
      third: {
        color: "#FFF",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 400,
        marginTop: "0px",
        marginLeft: "35px",
      },
    },
    paragraphs: {
      main: {
        textAlign: "end",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "2px",
      },
      second: {
        textAlign: "start",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "2px",
      },
      third: {
        textAlign: "end",
        color: "#FFF",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "2px",
      },
    },
  },
});

export default theme;
