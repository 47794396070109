import React, { useRef } from "react";
import { Button } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import theme from "../JeanStyles/theme";
import ECMobile from "../Images/EC/ECMobile.png";
import ECDesktop from "../Images/EC/ECDesktop.png";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";
import Grid from "@mui/material/Grid";

export default function EngineeringConsultingPage() {
  const dispatch = useDispatch();
  let PDDRef = useRef();
  let PAIRef = useRef();
  let ETMRef = useRef();

  const goToPDDRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: PDDRef.current.offsetTop - 100,
    });
  };
  const goToPAIRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: PAIRef.current.offsetTop - 100,
    });
  };
  const goToETMRef = () => {
    window.scrollTo({
      behavior: "smooth",
      top: ETMRef.current.offsetTop - 100,
    });
  };

  return (
    <div>
      <TopDescription
        goToPDDRef={goToPDDRef}
        goToPAIRef={goToPAIRef}
        goToETMRef={goToETMRef}
      />

      <ProductDesign setRef={PDDRef} />

      <ProcessAutomation setRef={PAIRef} />

      <EngineeringTechnology setRef={ETMRef} />
    </div>
  );
}

function TopDescription({ goToPDDRef, goToPAIRef, goToETMRef }) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      sx={{ mt: 2, mb: 4, width: "100%" }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <div
        style={{
          background: `url(${isMobile ? ECMobile : ECDesktop}) `,
          ...(isMobile
            ? theme.ecTopMobile.background.image
            : theme.ecTopBrowser.background.image),
        }}
      >
        <Grid
          item
          align="center"
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.ecTopMobile.headings.main
              : theme.ecTopBrowser.headings.main
          }
        >
          EC
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.ecTopMobile.headings.sub
              : theme.ecTopBrowser.headings.sub
          }
        >
          Engineering Consulting
        </Grid>

        <Grid
          sx={{ mt: 2, mb: 4 }}
          item
          xs={12}
          md={12}
          lg={12}
          style={
            isMobile
              ? theme.ecTopMobile.paragraphs.main
              : theme.ecTopBrowser.paragraphs.main
          }
        >
          Collaborate with competent engineers on your technical engineering
          project requirements. We provide dedicated short-, medium-, or
          long-term technical support on engineering and technology projects
          with flexible contracts.
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={11}
          sx={isMobile ? { mb: 10 } : { ml: { lg: "20px" } }}
          style={
            isMobile
              ? theme.ecTopMobile.bullets.main
              : theme.ecTopBrowser.bullets.headers
          }
        >
          <div
            class={isMobile ? "vl-ec-mobile-services-long" : "vl-top-ec-left"}
            onClick={goToPDDRef}
          >
            Product Design <br></br> and Development
          </div>
          <div
            class={isMobile ? "vl-ec-mobile-services-long" : "vl-top-ec-center"}
            onClick={goToPAIRef}
          >
            Process Automation <br></br> and Implementation
          </div>
          <div
            class={isMobile ? "vl-ec-mobile-services-long" : "vl-top-ec-right"}
            onClick={goToETMRef}
          >
            Engineering and <br></br>Technology Management
          </div>
          <br />
        </Grid>
        <br />
        <Grid
          item
          align={isMobile ? "center" : "left"}
          xs={12}
          md={12}
          lg={4.5}
          sx={isMobile ? { mt: 30 } : { paddingLeft: "200px" }}
        >
          <Button
            variant={"contained"}
            onClick={() => dispatch(setContactUsModalOpen(true))}
            style={
              isMobile ? theme.ecTopMobile.button : theme.ecTopBrowser.button
            }
          >
            <MessageIcon
              style={{ paddingRight: "5px", paddingTop: "5px" }}
            ></MessageIcon>
            LET'S TALK ABOUT YOUR PROJECT
          </Button>
        </Grid>
      </div>
    </Grid>
  );
}

function ProductDesign({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          sx={{ mb: { lg: -29 }, ml: 2 }}
          style={
            isMobile
              ? theme.ecTopMobile.bullets.main
              : theme.ecTopBrowser.bullets.main
          }
        >
          <div
            class={
              isMobile ? "vl-ec-mobile-services-long" : "vl-ec-desktop-services"
            }
          >
            {" "}
            Product Design and Development
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          style={
            isMobile
              ? theme.ecMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.main
          }
        >
          Navigate the entire lifecycle of your physical and digital products
          with our industry-leading services. From ideation to execution, our
          engineering consultants and dedicated business units are your trusted
          partners in crafting products that redefine excellence.
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          sx={{ mb: { xs: -5 } }}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>Comprehensive product design from ideation to execution</li>
            <li>Lifecycle management ensuring efficiency and innovation</li>

            <li>Customized solutions tailored to specific business needs</li>
            <li>
              Integration of cutting-edge technologies for enhanced
              functionality
            </li>
            <li>Iterative design processes for continuous improvement </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>Prototyping and testing to validate design decisions</li>

            <li>User-centric approach for product resonance with end-users</li>
            <li>
              Collaboration with clients to understand and align with their
              vision
            </li>
            <li>
              Full-stack development expertise for holistic product creation
            </li>

            <li>
              On-premises or remote consultation based on client preferences
            </li>
          </ul>
        </Grid>
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function ProcessAutomation({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2} sx={isMobile ? {} : { mt: 10 }}>
        <Grid item lg={2} />
        <Grid
          item
          xs={12}
          md={12}
          lg={8.7}
          sx={{ mb: { lg: -20 }, ml: 2 }}
          style={
            isMobile
              ? theme.ecTopMobile.bullets.main
              : theme.ecTopBrowser.bullets.right
          }
        >
          <div
            class={
              isMobile
                ? "vl-ec-mobile-services-long"
                : "vl-ec-desktop-services-right"
            }
          >
            Process Automation and Implementation
          </div>
        </Grid>
        <Grid item lg={12} />
        <Grid item lg={12} />
        <Grid
          item
          xs={12}
          md={12}
          lg={10.5}
          style={
            isMobile
              ? theme.ecMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.main
          }
        >
          <p>
            Revolutionize your operations with our end-to-end process automation
            consultancy. From ideation to full-scale implementation, we
            specialize in creating fit-for-purpose software solutions and
            developing digital twins, ensuring your business thrives in an era
            of automation.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          sx={{ mb: { xs: -5 } }}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>End-to-end process automation consultancy</li>
            <li>Development of fit-for-purpose software solutions</li>
            <li>Digital twin creation for enhanced process visualization</li>
            <li>Ideation to full-scale implementation of automation systems</li>
            <li>Integration of automation solutions with existing workflows</li>
          </ul>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{ mb: 3 }}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>Continuous monitoring for threat and bug identification</li>
            <li>Technical support and disaster recovery planning</li>
            <li>Securely managed and operated systems</li>
            <li>
              Standard operating procedures adherence and continuous improvement
            </li>
            <li>
              Cloud infrastructure optimization for evolving system requirements
            </li>
          </ul>
        </Grid>
      </Grid>
      <div class={isMobile ? "divider-ec-mobile" : "divider-ec-browser"}></div>
    </div>
  );
}

function EngineeringTechnology({ setRef }) {
  return (
    <div ref={setRef}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          sx={{ mb: { lg: -24 }, ml: 2 }}
          style={
            isMobile
              ? theme.ecTopMobile.bullets.main
              : theme.ecTopBrowser.bullets.main
          }
        >
          <div
            class={
              isMobile ? "vl-ec-mobile-services-long" : "vl-ec-desktop-services"
            }
          >
            {" "}
            Engineering and Technology Management
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          style={
            isMobile
              ? theme.ecMobile.paragraphs.main
              : theme.ecBrowser.paragraphs.bottom
          }
        >
          The hub of strategic engineering guidance, influencing the landscape
          of technology innovation and management. Specializing in precision
          through comprehensive technology stack audits and business strategy.
          Each decision is strategic, and every solution is sound, ensuring your
          technology environment is not just managed but optimized for
          efficiency and innovation.
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          sx={{ mb: { xs: -5 } }}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>Strategic alignment of engineering and business management</li>
            <li>Consulting for engineering-driven enterprises</li>
            <li>Implementation of Business Operations Management systems</li>
            <li>Guidance on maximizing organizational efficiency</li>
            <li>Collaboration between engineering and business units </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          style={
            isMobile
              ? theme.ecMobile.bullets.main
              : theme.ecBrowser.bullets.main
          }
        >
          <ul>
            <li>Data-driven decision-making for strategic planning</li>

            <li>Analysis and optimization of organizational workflows</li>
            <li>Business process reengineering for enhanced performance</li>
            <li>Continuous improvement methodologies for sustained growth</li>

            <li>Development and implementation of organizational policies</li>
          </ul>
        </Grid>
        <Grid item lg={1.5} sx={{ mb: { lg: 50 } }} />
      </Grid>
    </div>
  );
}
