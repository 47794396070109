import React from "react";
import { ReactComponent as DesktopIcon } from "../Images/background/home.svg";
import { ReactComponent as MobileIcon } from "../Images/homeMobile.svg";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import InfoIcon from "@mui/icons-material/Info";
import PaletteIcon from "@mui/icons-material/Palette";
import { isBrowser, isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

import theme from "../../Components/Styles/theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "react-swipeable-list/dist/styles.css";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { setContactUsModalOpen } from "../appSlice";
export default function HomePageIcon({ goToAbout }) {
  const [index, setIndex] = React.useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  const goToIndex = (num) => {
    setIndex(num);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      goToIndex(1);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      goToIndex(0);
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        <div style={isMobile ? {} : { marginTop: "20px" }}>
          <SwipeableViewIndexZero goToAbout={goToAbout} goToIndex={goToIndex} />
        </div>

        <div style={isMobile ? { marginTop: "-10px" } : { marginTop: "30px" }}>
          <SwipeableViewIndexOne goToAbout={goToAbout} goToIndex={goToIndex} />
        </div>
      </SwipeableViews>
    </>
  );
}

function SwipeableViewIndexZero({ goToAbout, goToIndex }) {
  return (
    <Grid item align="center" xs={12} sx={isBrowser ? {} : { mt: 4 }}>
      {isBrowser ? <DesktopIcon /> : <MobileIcon />}
      <p
        style={
          isBrowser
            ? theme.paragraphDesktopHome.root
            : theme.paragraphMobileHome.root
        }
      >
        Ensuring{" "}
        <b>
          <i>unique solutions</i>
        </b>{" "}
        exist
      </p>
      <Grid item xs={12} align="center" sx={{ mt: 5 }}>
        <Button
          color="primary"
          onClick={goToAbout}
          variant="contained"
          endIcon={<EmojiFlagsIcon />}
        >
          Begin the journey
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        align="center"
        sx={isMobile ? { mt: 3, mb: 5 } : { mt: 2 }}
      >
        <Button
          color="primary"
          onClick={() => {
            goToIndex(1);
          }}
          variant="text"
          startIcon={<InfoIcon />}
        >
          Cauchy?
        </Button>
      </Grid>
    </Grid>
  );
}

function SwipeableViewIndexOne({ goToAbout, goToIndex }) {
  const dispatch = useDispatch();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={isMobile ? { mt: 1 } : {}}
      >
        <Grid item xs={12} sx={isMobile ? { mt: 4, mb: -2 } : {}}>
          <p
            style={
              isBrowser
                ? theme.cauchyDesktopHome.root
                : theme.cauchyMobileHome.root
            }
          >
            <strong>
              <i> Cauchy?</i>
            </strong>
          </p>
        </Grid>

        <Grid item lg={2} />
        <Grid item align="center" lg={8}>
          <p
            style={
              isBrowser
                ? theme.cauchyDesktopHomeParagraph.root
                : theme.paragraphMobileHome.sub
            }
          >
            Our company's name is inspired by French mathematician
            Augustin-Louis
            <b>
              <i> Cauchy </i>
              {"  "}
            </b>{" "}
            who made numerous
            <strong>
              <i> pioneering contributions to mathematics </i>
            </strong>
            and mathematical physics.
            <strong>
              <i> Cauchy </i>
            </strong>
            boundary conditions augment ordinary or partial differential
            equations to
            <strong>
              <i> ensure a unique solution exists.</i>
            </strong>
          </p>
        </Grid>
        <Grid item lg={2} />
      </Grid>
      <Grid item xs={12} align="center" sx={isMobile ? { mt: 1 } : { mt: 5 }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<PaletteIcon />}
          onClick={() => dispatch(setContactUsModalOpen(true))}
        >
          Let's Create Something
        </Button>
      </Grid>
      {isBrowser ? (
        <Grid item xs={12} align="center" sx={{ mt: 5 }}>
          <KeyboardBackspaceIcon
            sx={{ color: "#fff" }}
            onClick={() => {
              goToIndex(0);
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
