import styled from 'styled-components';


export const FooterComponent = styled.div`
background: #013157;
width: 99vw;
text-align: center;
float: center;
display: flex;
justify-content: center;
align-items: center;
`;

export const FooterName = styled.a`
color: #fff;
font-size: 24px;
position:relative;
text-decoration: none;
justify-content: center;
align-items: center;
&:hover{
    cursor: pointer;
}

`;

export const SubName = styled.a`
color: #fff;
font-size: 20px;
position:relative;
text-decoration: none;
justify-content: center;
alignItems: center;
padding: 5px;

`;

