import { createTheme } from "@mui/material/styles";
import "@fontsource/roboto-flex/400.css"; // Specify weight

const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: [
      "-apple-system",
      "Montserrat",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto Flex",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  whatsappMobileHeader: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 30,
      color: "#2196f3",
      fontWeight: "regular",
      textAlign: "center",
    },
  },

  contactMainHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 30,
      color: "#ffffff",
      textAlign: "center",
      fontWeight: "lighter",
    },
    sub: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#ffffff",
      textAlign: "center",
      fontWeight: "lighter",
    },
  },

  bomMainHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 30,
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  },

  bomMainHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 70,
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  },

  bomSubHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#fff",
      textAlign: "center",
    },
  },
  bomSubHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#fff",
      textAlign: "left",
      marginTop: -10,
      "padding-left": "10px",
    },
  },

  subHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "left",
      marginTop: -32,
      "padding-left": "10px",
    },
  },
  bomHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
    },
  },

  bomBulletsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "left",
      justifyContent: "center",
      paddingLeft: "8px",
      paddingTop: 10,
    },
  },

  bomHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 70,
      color: "#fff",
      textAlign: "center",
    },
  },
  bomParagraphHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 14,
      color: "#fff",
      textAlign: "left",
      marginLeft: 10,
    },
    sub: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 14,
      color: "#fff",
      textAlign: "center",
      padding: 2,
    },
  },

  bomParagraphHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 24,
      color: "#fff",
      textAlign: "center",
    },
  },

  bomDe: {
    browser: {
      color: "#FFF",
      textAlign: "center",
      fontSize: "60px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  paragraphHeaders: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 32,
      color: "#fff",
      textAlign: "left",
      fontWeight: "bold",
      marginTop: "-2px",
      "padding-left": "10px",
      "&:hover": {
        textShadow: "2px 2px 8px",

        cursor: "pointer",
      },
    },
  },

  paragraphHeadersMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "left",
      fontWeight: "bold",
      marginTop: -5,
      "padding-left": "10px",
    },
  },

  homeHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      color: "rgba(20, 60, 136)",
      textAlign: "left",
      fontWeight: "bold",
      // opacity: 0.4,
    },
  },
  homeHeadingsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 120,
      color: "rgba(20, 60, 136, 0.40)",
      textAlign: "left",
      marginLeft: 80,
      fontWeight: "bold",
      lineHeight: "normal",
      // opacity: 0.4,
    },
  },

  technologyHeadingsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 80,
      color: "#fff",
      textAlign: "left",
      marginLeft: 80,
      fontWeight: "bold",
      opacity: 0.4,
    },
  },

  contactCardStyle: {
    background: " rgba(227,233,245,0.4)",
    backgroundColor: "rgba(0,0,0,. 4)",
    borderRadius: 20,
    // borderColor: "#6E91D4",
    // borderStyle: "dashed",
    minHeight: "340px",
    maxHeight: "380px",
    width: "90%",
    marginRight: 0,
  },

  contactCardStyleDesktop: {
    background: " rgba(227,233,245,0.4)",
    backgroundColor: "rgba(0,0,0,. 4)",
    borderRadius: 12,
    // border: "2.419px dashed rgba(110, 145, 212, 0.40)",

    minHeight: "340px",
    maxHeight: "380px",
    width: "350px",
    // border-radius: 12px;
    // border: 2.419px dashed rgba(110, 145, 212, 0.40);
  },

  mobileServicesCardStyle: {
    de: {
      minHeight: "258.11px",
      maxHeight: "300.11px",
      width: "100%",
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
    },
    ec: {
      minHeight: "258.11px",
      maxHeight: "300.11px",
      width: "100%",
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
    },
    cae: {
      minHeight: "268.11px",
      maxHeight: "300.11px",
      width: "100%",
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
  },

  desktopServicesCardStyle: {
    cae: {
      minHeight: "465px",
      maxHeight: "465px",
      borderTopLeftRadius: 25,
      borderBottomLeftRadius: 25,
      width: "100%",
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
    },
    de: {
      minHeight: "465px",
      maxHeight: "465px",
      width: "100%",
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
    },
    ec: {
      minHeight: "465px",
      maxHeight: "465px",
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      width: "100%",
      marginRight: 0,
      background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
    },
  },

  mobileHomeServicesHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      top: 0,
      textAlign: "left",
      padding: "0px",
      margin: "0px",
    },
  },
  mobileHomeServicesAbbrvHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 14,
      color: "#fff",
      top: 0,
      textAlign: "left",
      padding: "1px",
      marginBottom: "0px",
      marginTop: "-10px",
    },
  },

  desktopHomeServicesAbbrvHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      fontWeight: 600,
      color: "#fff",
      top: 0,
      textAlign: "left",
      padding: "1px",
      marginBottom: "0px",
      marginTop: "-10px",
    },
    sub: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      fontWeight: 600,
      color: "#fff",
      top: 0,
      textAlign: "left",
      padding: "1px",
      marginBottom: "0px",
      marginTop: "20px",
    },
  },
  desktopHomeServicesHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 52,
      fontWeight: 300,
      color: "#fff",
      top: 0,
      textAlign: "left",
      marginBottom: "50px",
      marginTop: "-5px",
    },
  },

  CTAButtons: {
    borderRadius: 20,
    backgroundColor: "#d1a86b",
    color: "#ffffff",
    borderColor: "#ffffff",

    "&:hover": {
      backgroundColor: "#c4773f",
      color: "#4F4F4F",
      borderColor: "#ffffff",
    },
  },

  paragraphServicesMobile: {
    fontFamily: "Roboto Flex",
    fontSize: 14,
    color: "#fff",
    textAlign: "left",
  },

  paragraphServicesDesktop: {
    fontFamily: "Roboto Flex",
    fontSize: 20,
    fontWeight: 300,
    color: "#fff",
    textAlign: "left",
    marginBottom: "40px",
  },

  paragraphDesktopHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 50,
      color: "#fff",
      textAlign: "center",
      paddingTop: "20px",
    },
  },
  cauchyDesktopHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 70,
      color: "#2B60C3",
      textAlign: "center",
      paddingTop: "20px",
    },
  },
  cauchyDesktopHomeParagraph: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 30,
      color: "#fff",
      textAlign: "center",
    },
  },

  cauchyMobileHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 24,
      color: "#2B60C3",
      textAlign: "center",
    },
  },

  paragraphMobileHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
      paddingTop: "20px",
    },
    sub: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#fff",
      textAlign: "center",
      paddingTop: "20px",
    },
  },

  paragraphMobileServices: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "left",
      paddingTop: "20px",
    },
  },
  aboutHeadingDesktop: {
    root: {
      color: "rgba(20, 60, 136, 0.40)",
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 120,
      textAlign: "left",
      marginLeft: "180px",
      fontWeight: "bold",
      marginTop: "150px",
      marginBottom: "20px",
    },
    services: {
      color: "rgba(20, 60, 136, 0.40)",
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 120,
      textAlign: "left",
      marginLeft: 80,
      fontWeight: "bold",
      marginBottom: "-1px",
    },

    tech: {
      color: "rgba(20, 60, 136, 0.40)",
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 120,
      textAlign: "left",
      fontWeight: "bold",
      marginTop: "150px",
      marginBottom: "-1px",
    },
    techMobile: {
      color: "rgba(20, 60, 136, 0.40)",
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      textAlign: "left",
      fontWeight: "bold",
      marginTop: "150px",
      marginBottom: "-1px",
    },
  },
  aboutHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 64,
      color: "rgba(20, 60, 136, 0.40)",
      textAlign: "center",
      fontWeight: "bold",
      // opacity: 0.3,
      marginTop: "20px",
      marginBottom: "-1px",
    },
  },

  aboutSubHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 32,
      color: "#fff",
      textAlign: "right",
      fontWeight: "bold",
    },
    sub: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 32,
      color: "#fff",
      textAlign: "center",
      fontWeight: "bold",
    },
  },
  aboutSubHeadingsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 32,
      color: "#fff",
      textAlign: "left",
      marginLeft: "350px",
    },
  },

  aboutParagraphsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "left",
    },
  },
  aboutParagraphsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "center",
    },
  },
  aboutPodcastFontLightMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 26,
      color: "#4D78C9",
      textAlign: "left",
    },
  },
  aboutPodcastFontDarkMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 26,
      color: "#143C88",
      textAlign: "left",
    },
  },

  aboutPodcastFontLightDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 26,
      color: "#4D78C9",
      textAlign: "right",
      marginRight: "100px",
    },
  },
  aboutPodcastFontDarkDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 26,
      color: "#143C88",
      marginRight: "100px",
      textAlign: "right",
    },
  },

  theTeamHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "center",
    },
  },
  theTeamSubHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 14,
      color: "#fff",
      textAlign: "center",
    },
  },

  aboutScrollsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
    },
  },

  footerCityLeftMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 32,
    color: "#85D3FF",
    textAlign: "left",
  },
  footerCityRigthMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 32,
    color: "#85D3FF",
    textAlign: "right",
  },

  footerStateLeftMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "#85D3FF",
    textAlign: "left",
  },
  footerStateRigthMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "#85D3FF",
    textAlign: "right",
  },

  footerContactLeftMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "#fff",
    textAlign: "left",
  },
  footerContactRigthMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "#fff",
    textAlign: "right",
  },
  footerOfficesLeftMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.50)",
    textAlign: "left",
  },
  footerOfficesRigthMobile: {
    fontFamily: ["Roboto Flex", "serif"].join(","),
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.50)",
    textAlign: "right",
    marginLeft: "80px",
  },
});

export default theme;
