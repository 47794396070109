import * as React from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CallIcon from "@mui/icons-material/Call";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, CardActions } from "@mui/material";

import myVideo from "../Components/Videos/dem.mp4"
import ReactPlayer from 'react-player'
//Redux
import { useSelector, useDispatch } from "react-redux";
import { getVideoModalOpen, setVideoModalOpen } from "./appSlice";
import theme from "./theme";
import ClearIcon from "@mui/icons-material/Clear";

export default function WatchVideo() {

   
  const dispatch = useDispatch();
  const video_modal_open = useSelector(getVideoModalOpen);

    const handleClose = () => {
      dispatch(setVideoModalOpen(false));
    };

    return (
        <Dialog
            BackdropProps={{ invisible: true }}
            fullWidth
            maxWidth="md"
            open={video_modal_open}
            onClose={handleClose}
        >
            <DialogTitle>
                <ClearIcon sx={{ top: 0, right: 0 }} onClick={handleClose} />

                <Typography style={theme.modalHeading.root}>Make the most of the website</Typography>

            </DialogTitle>
            <DialogContent>
                <Grid container gap={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} direction="row" justifyContent="center" alignItems="center">

                    <Grid item align="center" xs={12} md={8}>
                    <ReactPlayer
        // light="true"
        controls="true"
        url={myVideo}
        // ...
      />
                         </Grid>

      
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

