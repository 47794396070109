import React from "react";

import { FooterName, SubName, FooterComponent } from "./FooterStyles";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isMobile, isBrowser } from "react-device-detect";

import footerMobile from "../Images/background/Footer_Mobile.png";
import footerDesktop from "../Images/background/mountains.png";
import theme from "../Styles/theme";
import FooterLogo from "./DesktopLogo";
import { ReactComponent as DesktopIcon } from "../Images/background/mountains.svg";
import { useDispatch } from "react-redux";
import { setContactUsModalOpen } from "../appSlice";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Footer() {
  const goToDEM = () => {
    window.location.pathname = "/our-services/digital-engineering";
  };
  const goToCAE = () => {
    window.location.pathname = "/our-services/computer-aided-engineering";
  };
  const goToMEC = () => {
    window.location.pathname = "/our-services/engineering-consulting";
  };

  const goToAbout = () => {
    window.location.pathname = "/our-purpose";
  };
  const goToHome = () => {
    window.location.pathname = "/";
  };
  const goToPrivacyPolicy = () => {
    window.location.pathname = "/privacy-policy";
  };
  const dispatch = useDispatch();
  return (
    <>
      <FooterComponent>
        <div
          style={
            isMobile
              ? {
                  backgroundImage: `url(${footerMobile})`,
                  height: "1175px",
                  width: "100vw",
                  textAlign: "center",
                  float: "center",
                  backgroundSize: "cover",
                }
              : {
                  backgroundImage: `url(${footerDesktop})`,
                  height: "900px",
                  width: "100%",
                  textAlign: "center",
                  float: "center",
                  backgroundSize: "cover",
                }
          }
        >
          <Grid
            container
            sx={{ mt: 2, mb: 4, width: { xs: "100%", lg: "100%" }, p: 2 }}
            spacing={1}
            direction="row"
            justifyContent={{ xs: "left", lg: "left" }}
            alignItems={{ xs: "left", lg: "top" }}
          >
            {isBrowser ? <Grid item align="left" lg={1}></Grid> : ""}
            <Grid item align="left" xs={10} lg={2}>
              <Divider content={"Home"} onclick={goToHome} />
            </Grid>
            {isMobile ? (
              <>
                <Grid item xs={2} align="right">
                  <FooterLogo />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <br />
            <Grid item align="left" xs={12} lg={2}>
              <Divider content={"Our Purpose"} onclick={goToAbout} />
            </Grid>
            <br />

            <Grid item align="left" xs={12} lg={2}>
              <Divider content={"Our Services"} />
              <DividerSub content={"CAE"} onclick={goToCAE} />
              <DividerSub content={"DE"} onclick={goToDEM} />
              <DividerSub content={"EC"} onclick={goToMEC} />
            </Grid>
            <br />

            <Grid item align="left" xs={12} lg={2}>
              <Divider
                content={"Contact Us"}
                onclick={() => dispatch(setContactUsModalOpen(true))}
              />
            </Grid>

            {isBrowser ? (
              <>
                <Grid item lg={2} align="right">
                  <FooterLogo />
                  <Grid item lg={1} align="right" />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <br />
          </Grid>
          {isMobile ? (
            <>
              <OfficeCapeTownMobile />
              <OfficeLondonMobile goToPrivacyPolicy={goToPrivacyPolicy} />
            </>
          ) : (
            <>
              <OfficesDesktop goToPrivacyPolicy={goToPrivacyPolicy} />
            </>
          )}
        </div>
      </FooterComponent>
    </>
  );
}

function OfficeLondonMobile({ goToPrivacyPolicy }) {
  const whatsappUK = "https://wa.me/message/GTFI3DNGQVLOH1";
  return (
    <>
      <Grid
        container
        sx={{ mt: 1, p: 4 }}
        spacing={1}
        direction="row"
        justifyContent="justify"
        alignItems="center"
      >
        <Grid item align="left" xs={12}>
          <h style={theme.footerCityLeftMobile}>United Kingdom</h>
        </Grid>

        <Grid item align="left" xs={12}>
          <h style={theme.footerOfficesLeftMobile}>
            Cauchy Consult UK LTD <br />
            Company number: 14027534 <br />
            <br />
            86-90 Paul Street <br />
            London <br />
            England <br />
            EC2A 4NE
          </h>
        </Grid>

        <Grid item sx={12} align="left">
          <p style={{ fontSize: 16, color: "#fff" }}>
            © Cauchy Consult 2023 <br />
            All Rights Reserved
          </p>
          <DividerPrivacy
            content={"Privacy Policy"}
            onclick={goToPrivacyPolicy}
          />
        </Grid>
      </Grid>
    </>
  );
}
function OfficeCapeTownMobile() {
  return (
    <>
      <Grid
        container
        sx={{ mb: 4, p: 4 }}
        spacing={1}
        direction="row"
        justifyContent="justify"
        alignItems="center"
      >
        <Grid item align="left" xs={12}>
          <h style={theme.footerCityRigthMobile}>South Africa</h>
        </Grid>

        <Grid item align="left" xs={12}>
          <h style={theme.footerOfficesLeftMobile}>
            Cauchy Consult (Pty) ltd <br />
            Registration number: 2020/534274/07 <br />
            <br />
            Dock Road Junction <br />
            Corner of Stanley & Dock Road <br />
            Waterfront <br />
            Cape Town <br />
            8001
          </h>
        </Grid>
      </Grid>
    </>
  );
}

function OfficesDesktop({ goToPrivacyPolicy }) {
  return (
    <>
      <Grid
        container
        sx={{ mt: 1, p: 1 }}
        direction="row"
        justifyContent="left"
        alignItems="top"
      >
        <Grid item lg={1} align="left" />

        <Grid item lg={4} align="left">
          <h style={theme.footerCityLeftMobile}>South Africa</h>
          <br />
          <h style={theme.footerOfficesLeftMobile}>
            Cauchy Consult (Pty) ltd <br />
            Registration number: 2020/534274/07 <br />
            <br />
            Dock Road Junction <br />
            Corner of Stanley & Dock Road <br />
            Waterfront <br />
            Cape Town <br />
            8001
          </h>
        </Grid>
        <Grid item lg={4} align="left">
          <h style={theme.footerCityLeftMobile}>United Kingdom</h> <br />
          <h style={theme.footerOfficesLeftMobile}>
            Cauchy Consult UK LTD <br />
            Company number: 14027534 <br />
            <br />
            86-90 Paul Street <br />
            London <br />
            England <br />
            EC2A 4NE
          </h>
        </Grid>

        <Grid item lg={3} align="left" justifyContent="top">
          <p style={{ fontSize: 24, color: "#fff" }}>
            © Cauchy Consult 2023 <br />
            All Rights Reserved
          </p>
          <DividerPrivacy
            content={"Privacy Policy"}
            onclick={goToPrivacyPolicy}
          />
        </Grid>
      </Grid>
    </>
  );
}

function Divider({ content, onclick }) {
  return (
    <FooterName onClick={onclick}>
      {content} <br />
    </FooterName>
  );
}
function DividerSub({ content, onclick }) {
  return (
    <>
      <br />
      <Button
        variant="text"
        endIcon={<ArrowForwardIosIcon />}
        onClick={onclick}
        sx={{ color: "#fff", fontSize: "20px" }}
      >
        {content}
      </Button>
    </>
  );
}
function DividerPrivacy({ content, onclick }) {
  return (
    <>
      <br />
      <Button
        variant="text"
        endIcon={<ArrowForwardIosIcon />}
        onClick={onclick}
        sx={{ color: "#fff", fontSize: "12px" }}
      >
        {content}
      </Button>
    </>
  );
}
export default Footer;
