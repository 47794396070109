import { createSlice } from "@reduxjs/toolkit/";

const initialState = {
  contact_us_modal_open: false,
  whatsapp_modal_open: false,
  video_modal_open: false,
  openServices: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setContactUsModalOpen: (state, action) => {
      state.contact_us_modal_open = action.payload;
    },
    setWhatsappModalOpen: (state, action) => {
      state.whatsapp_modal_open = action.payload;
    },
    setVideoModalOpen: (state, action) => {
      state.video_modal_open = action.payload;
    },
    setOpenServices: (state, action) => {
      state.openServices = action.payload;
    },
  },
});

export const getContactUsModalOpen = (state) => state.app.contact_us_modal_open;
export const getWhatsappModalOpen = (state) => state.app.whatsapp_modal_open;
export const getVideoModalOpen = (state) => state.app.video_modal_open;
export const getOpenServices = (state) => state.app.openServices;

export const { 
    setContactUsModalOpen, setOpenServices, setWhatsappModalOpen, setVideoModalOpen
} = appSlice.actions;

export default appSlice.reducer;
