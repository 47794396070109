import { blue, green, grey, orange, purple, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import "@fontsource/roboto-flex/400.css"; // Specify weight
// import createBreakpoints from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      contrastText: "#fff",
    },

    information: {
      main: blue[500],
    },

    secondary: {
      main: red[500],
    },

    error: {
      main: red[500],
    },

    warning: {
      main: orange[500],
      contrastText: "#fff",
    },

    // Success
    success: {
      main: green[500],
      contrastText: "#fff",
    },

    // edit
    edit_button: {
      main: blue[500],
      contrastText: "#fff",
    },
    reorder_level_modal: {
      main: blue[200],
      modalBackdrop: "rgb(33, 150, 243,0.3)",
    },

    receive_overflow_modal: {
      main: purple["A400"],
      modalBackdrop: "rgb(156, 39, 176, 0.2)",
    },

    cancel_button: {
      main: grey[500],
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Roboto-Flex",
      "-apple-system",
      "Montserrat",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto Flex",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  // https://stackoverflow.com/questions/69751510/how-to-have-different-color-for-alternate-row-for-mui-v4-table

  serviceButton: {
    root: {
      color: "#15cdfc",
    },
  },

  blueButtonStyle: {
    root: {
      background: "#3F8CC4",
      border: 0,
      borderRadius: 20,
      color: "white",
    },
  },
  warningButtonStyle: {
    background: "#c4773f",
    border: 0,
    borderRadius: 20,
    color: "white",
    "&:hover": {
      backgroundColor: "#d1a86b",
      color: "#4F4F4F",
      borderColor: "#ffffff",
    },
  },

  rounded: {
    border: 0,
    borderRadius: 20,
  },
  filterButtonStyle: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      background: "#e7d5f0",
      border: 0,
      borderRadius: 3,
      // boxShadow: '0 3px 5px 2px rgba(18, 204, 52, .3)',
      color: "#c5dfe8",
    },
  },

  cardStyleLight: {
    root: {
      background: "#6ca7d2",
    },
  },

  video: {
    position: "relative",
    textAlign: "center",
  },

  serviceHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 40,
      color: "#3F8CC4",
      top: 0,
      textAlign: "center",
      // paddingTop: "10%",
      textShadow: "1px 1px 2px #7694b3",
    },
  },
  bottomNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
      color: "#fe6c04",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
  },
  purposeHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 34,
      color: "#3F8CC4",
      top: 0,
      textAlign: "center",

      textShadow: "1px 1px 2px #7694b3",
    },
  },

  mobileHomeServicesHeading3: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 24,
      color: "#000",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",
    },
  },

  mobileHomeServicesHeading2: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 20,
      color: "#000",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",
    },
  },

  darkSubHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 32,
      color: "#000",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",
    },
  },

  cardHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 28,
      color: "#3F8CC4",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      paddingBottom: "3%",
      margin: "0px",

      textShadow: "1px 1px 2px #7694b3",
    },
  },

  modalHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 32,
      color: "#3F8CC4",
      top: 0,
      textAlign: "center",
      padding: "0px",
      margin: "0px",
    },
  },
  serviceSub: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 24,
      color: "#3F8CC4",
      textAlign: "center",

      // 'textShadow': "1px 1px 2px #7694b3",
    },
  },
  mainHeadingDesktop: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 36,
      color: "#3F8CC4",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",

      textShadow: "1px 1px 2px #7694b3",
    },
  },
  mainHeadingMobile: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 28,
      color: "#ffffff",
      top: 0,
      textAlign: "center",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",

      textShadow: "1px 1px 2px #7694b3",
    },
  },

  paragraph: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 22,
      color: "#ffffff",
      textAlign: "center",
      paddingTop: "20px",
    },
  },

  paragraphBullets: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 22,
      color: "#ffffff",
      textAlign: "left",
    },
  },

  paragraphDark: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 22,
      color: "#fff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphModal: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 17,
      color: "#000",
      textAlign: "left",
    },
  },

  paragraphBig: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 20,
      color: "#ffffff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphBigDark: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphBig2: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 18,
      color: "#ffffff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphDesktop: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 17,
      color: "#ffffff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },
  // new code

  contactMainHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 28,
      color: "#ffffff",
      textAlign: "center",
      fontWeight: "lighter",
    },
  },

  bomMainHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 30,
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  },

  bomMainHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 70,
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  },

  bomSubHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#fff",
      textAlign: "center",
    },
  },
  bomSubHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 16,
      color: "#fff",
      textAlign: "left",
      marginTop: -10,
    },
  },

  subHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 24,
      color: "#fff",
      textAlign: "left",
      marginTop: -10,
    },
  },
  bomHeaderHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
    },
  },

  bomBulletsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "center",
    },
  },

  bomHeaderHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 70,
      color: "#fff",
      textAlign: "center",
    },
  },
  bomParagraphHomeMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 14,
      color: "#fff",
      textAlign: "center",
    },
  },

  bomParagraphHomeDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 24,
      color: "#fff",
      textAlign: "center",
    },
  },

  paragraphHeaders: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 32,
      color: "#fff",
      textAlign: "left",
      fontWeight: "bold",
      marginTop: "-2px",
      "padding-left": "10px",
      // paddingTop: "20px",
      "&:hover": {
        textShadow: "2px 2px 8px",

        cursor: "pointer",
      },
    },
  },

  paragraphHeadersMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 18,
      color: "#fff",
      textAlign: "left",
      fontWeight: "bold",
      marginTop: -5,
      "padding-left": "10px",
      // paddingTop: "20px",
    },
  },

  homeHeadingsMobile: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 40,
      color: "#fff",
      textAlign: "left",
      fontWeight: "bold",
      opacity: 0.4,
    },
  },
  homeHeadingsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 120,
      color: "#fff",
      textAlign: "left",
      marginLeft: 80,
      fontWeight: "bold",
      opacity: 0.4,
    },
  },

  technologyHeadingsDesktop: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 80,
      color: "#fff",
      textAlign: "left",
      marginLeft: 80,
      fontWeight: "bold",
      opacity: 0.4,
    },
  },

  contactCardStyle: {
    background: " rgba(227,233,245,0.4)",
    backgroundColor: "rgba(0,0,0,. 4)",
    borderRadius: 20,
    borderColor: "#6E91D4",
    borderStyle: "dashed",
    minHeight: "399px",
    maxHeight: "450px",
    width: "95%",
    marginRight: 0,
    // background: '#E3E9F5',
    // backgroundOpacity: .4,
  },

  contactCardStyleDesktop: {
    background: " rgba(227,233,245,0.4)",
    backgroundColor: "rgba(0,0,0,. 4)",
    borderRadius: 20,
    borderColor: "#6E91D4",
    borderStyle: "dashed",
    minHeight: "340px",
    maxHeight: "340px",
    width: "791px",
    // background: '#E3E9F5',
    // backgroundOpacity: .4,
  },

  mobileServicesCardStyle: {
    minHeight: "198.11px",
    maxHeight: "218.11px",
    width: "100%",
    marginRight: 0,
    background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
  },

  desktopServicesCardStyle: {
    minHeight: "405px",
    maxHeight: "405px",
    width: "100%",
    marginRight: 0,
    background: "linear-gradient(270deg, #142850 20.03%, #143C88 97.92%)",
  },

  mobileHomeServicesHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      top: 0,
      textAlign: "left",
      // 'padding-left': "50px",
      padding: "0px",
      margin: "0px",
    },
  },
  desktopHomeServicesHeading: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 52,
      color: "#fff",
      top: 0,
      textAlign: "left",
      marginBottom: "70px",
      marginTop: "-5px",
    },
  },

  CTAButtons: {
    borderRadius: 20,
    backgroundColor: "#d1a86b",
    color: "#ffffff",
    borderColor: "#ffffff",

    "&:hover": {
      backgroundColor: "#c4773f",
      color: "#4F4F4F",
      borderColor: "#ffffff",
    },
  },

  paragraphServicesMobile: {
    fontFamily: "Roboto Flex",
    fontSize: 14,
    color: "#fff",
    textAlign: "left",
  },

  paragraphServicesDesktop: {
    fontFamily: "Roboto Flex",
    fontSize: 20,
    fontWeight: 300,
    color: "#fff",
    textAlign: "left",
    marginBottom: "70px",
  },

  paragraphDesktopHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 50,
      color: "#fff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphMobileHome: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 20,
      color: "#fff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  // old code
  paragraphDesktopDark: {
    root: {
      fontFamily: ["Roboto Flex", "serif"].join(","),
      fontSize: 17,
      color: "#fff",
      textAlign: "center",
      // 'padding-left': "20px",
      paddingTop: "20px",
    },
  },

  paragraphMobile: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 17,
      color: "#ffffff",
      textAlign: "center",
      paddingTop: "20px",
    },
  },

  imageStyles: {
    root: {
      // textAlign: 'left',

      padding: "0px",
      margin: "0px",

      // 'textShadow': "1px 1px 2px #7694b3",
    },
  },

  paddingstyles: {
    root: {
      paddingTop: "20px",
    },
  },
  paddingstyles2: {
    root: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },

  links: {
    root: {
      color: "white",
    },
  },

  subHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 36,
      color: "#ffffff",
      textAlign: "center",
      position: "relative",
      top: 0,
      // 'textShadow': "1px 1px 2px #7694b3",
    },
  },
  italicHeading: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 28,
      color: "#3F8CC4",
    },
  },
  italictext: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      fontSize: 24,
      color: "#ffffff",
      textAlign: "center",
      position: "relative",

      //   paddingTop: "10px",
      // 'textShadow': "1px 1px 2px #7694b3",
    },
  },
  media: {
    paddingTop: "56.25%", // 16:9,
    marginTop: "30",
  },
  bgcolour: {
    color: "#081328",
  },

  modalHeadingStyle: {
    root: {
      fontFamily: ["Montserrat", "serif"].join(","),
      background: "linear-gradient(45deg, #2196F3 30%, #21BDF3 90%)",
      border: 0,
      borderRadius: 3,
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
    },
  },

  carousalP: {
    fontFamily: ["Montserrat", "serif"].join(","),
    fontSize: 16,
  },

  darkCTAButtons: {
    borderRadius: 20,
    backgroundColor: "#d1a86b",
    color: "#ffffff",
    borderColor: "#ffffff",

    "&:hover": {
      backgroundColor: "#c4773f",
      color: "#4F4F4F",
      borderColor: "#ffffff",
    },
  },

  scrollableButtons: {
    color: "#000",
    "font-size": "17px",
    "&:hover": {
      backgroundColor: "#d1a86b",
      color: "#ffffff",
      borderColor: "#ffffff",
    },
  },

  scrollableHomeButtons: {
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#d1a86b",
      color: "#d1a86b",
      borderColor: "#ffffff",
    },
  },

  modalButtonStyle: {
    root: {
      background: "#c4773f",
      border: 0,
      borderRadius: 20,
      color: "white",
    },
  },

  svgIcon: {
    width: 250,
    height: 60,
  },

  dividerStyle: {
    width: "76px",
    height: 100,
    transform: "rotate(90deg)",
    transformOrigin: "0 0",
    border: "8px #E1AE7E solid",
    position: "absolute",
    left: 0,
    top: 0,
  },

  // Styles For DE Page Mobile ----------------------------------------------------------------------------------------------------------
  dePageSection1Mobile: {
    background: {
      image: {
        width: "430px",
        height: "680px",
        flexShrink: 0,
        opacity: 0.2,
      },
    },
    headingsMobile: {
      main: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Roboto Flex",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
      },
      sub: {
        width: "387px",
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
    },
    paragraphsMobile: {
      main: {
        alignSelf: "stretch",
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
    bulletsMobile: {
      main: {
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "50px",
      },
    },
    buttonMobile: {
      // Define styles for the "LET'S TALK ABOUT YOUR PROJECT" button
      // Add any other styles you need
    },
  },

  // Styles For DE Page Browser ----------------------------------------------------------------------------------------------------------
  dePageSection1Browser: {
    background: {
      image: {
        width: "430px",
        height: "680px",
        flexShrink: 0,
        opacity: 0.2,
      },
    },
    headingsBrowser: {
      main: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Roboto Flex",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
      },
      sub: {
        width: "387px",
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
    },
    paragraphsBrowser: {
      main: {
        alignSelf: "stretch",
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
    bulletsBrowser: {
      main: {
        color: "#FFF",
        fontFamily: "Roboto Flex",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "50px",
      },
    },
    buttonBrowser: {
      // Define styles for the "LET'S TALK ABOUT YOUR PROJECT" button
      // Add any other styles you need
    },
  },

  // Styles For EC Page Mobile ----------------------------------------------------------------------------------------------------------

  ecPageSection1Mobile: {
    background: {
      image: {
        width: "100%",
        height: "680px",
      },
    },
    headingsMobile: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "119px ",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      sub: {
        width: "100%",
        textAlign: "start",
        color: "#FFF",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingLeft: "24px",
        paddingBottom: "20px",
        paddingRight: "24px",
      },
    },
    paragraphsMobile: {
      main: {
        width: "100%",
        paddingLeft: "24px",
        textAlign: "start",
        color: "#FFF",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingRight: "24px",
      },
    },
    bulletsMobile: {
      main: {
        width: "100%",
        paddingRight: "24px",
        paddingLeft: "10px",
        color: "#FFF",
        textAlign: "start",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
      },
    },
    buttonMobile: {
      padding: "8px 22px",
      background: "#FFA44F",
      color: "#FFF",
      borderRadius: "4px",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
      paddingRight: "53px",
      paddingLeft: "53px",
    },
  },

  ecPageSection2Mobile: {
    headingsMobile: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingTop: "119px ",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    bulletsMobile: {
      main: {
        width: "100%",
        paddingRight: "24px",
        paddingLeft: "10px",
        color: "#FFF",
        textAlign: "start",
        fontFamily: ["Roboto", "Roboto-Flex"].join(","),
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "44px",
      },
    },
    customLineMobile: {
      width: "76px",
      border: "8px",
      rotation: "-90",
      backgroundColor: "#E1AE7E",
    },
  },

  // Styles For EC Page Browser ----------------------------------------------------------------------------------------------------------

  ecPageSection1Browser: {
    background: {
      image: {
        width: "100%",
        height: "700px",
        flexShrink: 0,
      },
    },
    headingsBrowser: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontFamily: "Roboto ",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        paddingTop: "20px",
      },
      sub: {
        width: "387px",
        textAlign: "start",
        color: "#FFF",
        fontFamily: "Roboto ",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        paddingTop: 2,
      },
    },
    paragraphsBrowser: {
      main: {
        alignSelf: "stretch",
        textAlign: "start",
        color: "#FFF",
        fontFamily: "Roboto ",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
    bulletsBrowser: {
      main: {
        color: "#FFF",
        textAlign: "start",
        fontFamily: "Roboto ",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "50px",
      },
    },
    buttonBrowser: {
      // Define styles for the "LET'S TALK ABOUT YOUR PROJECT" button
      // Add any other styles you need
    },
  },
});

export default theme;
