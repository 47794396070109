import React from "react";
import { Grid } from "@mui/material";
import Marquee from "react-fast-marquee";
import marqueeTop from "./TechsMarqueeTop.json"
import marqueeBottom from "./TechsMarqueeBottom.json"
function Technologies() {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        md={10}
        sx={{ ml: { lg: 20 } }}
      >
        <Marquee
          gradient={false}
          speed={30}
          direction={"right"}
          style={{ backgroundColor: "#fff" }}
        >
          <Grid item align="center" xs={14} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
            <div style={{ backgroundColor: "#fff" }}>
            {marqueeTop.map((marquee) => (
              <>
              <img src={require(`../Images/${marquee.img}`)}width="100px" position="absolute" alt=""></img>
           
                 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            
                 </>))}
            </div>
            
          </Grid>
        </Marquee>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        md={10}
        sx={{ ml: { lg: 20 } }}
      >
        <Marquee
          gradient={false}
          speed={30}
          style={{ backgroundColor: "#fff" }}
        >
          <Grid item align="center" xs={14} sm={12} md={12} sx={{ mt: 2 }}>
            <div style={{ backgroundColor: "#fff" }}>
            {marqueeBottom.map((marquee) => (
              <>
              <img src={require(`../Images/${marquee.img}`)}width="100px" position="absolute" alt=""></img>
                 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            
                 </>))}
            </div>
          </Grid>
        </Marquee>
      </Grid>
    </>
  );
}

export default Technologies;
